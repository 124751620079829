import './RequestType.scss';

const RequestType = ({ disabled, onOptionChange, options, selectedType, suffix, setSelectedFormType, isActive }) => {    
  return (
    <div className={`request-type-container ${disabled ? 'disabled' : ''}`}>
        <button
            className={`action-btn ${isActive ? 'active' : ''}`}
            onClick={setSelectedFormType}
        >
            {selectedType + ' ' + suffix}
        </button>
        <div className='request-types-wrapper'>
            {options.map((option, index) => {
                return (
                    <button key={index} onClick={() => {
                        onOptionChange(option)
                    }}>
                        {option.name}
                    </button>
                )
            })}
        </div>
    </div>
  )
}

export default RequestType