import { useEffect, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "components/Popover";
import APIService from "services/apiService";
import { alertService } from "services/alertService";
import { useGlobalContext } from "contexts/GlobalContext";
import StandardShiftDetailCard from "../StandardShiftDetailCard";
import LineLoader from "components/LineLoader";
import { format, getWeek } from "utils/date";
import { formatTime, isNotNone } from "views/Attendance/utils";
import { convertHhmmToUserTimezone } from "utils/Common";
import { AL_BASE_URL } from "../../constants";
import "./AttendanceValue.scss";

const AttendanceValue = ({
    data,
    userShiftDetails,
    setUserShiftDetails,
    isOfficeFloor = false,
    date,
}) => {
    const isOnLeave = data.attendance.includes("leave");
    const isOnSpecialShift = isNotNone(data.special_shift_time);
    const isWeeklyOff = data.day_status === "weekly_off";
    const holiday = isNotNone(data.holiday_name) ? data.holiday_name : "";
    const isStandard =
        !isOnSpecialShift && !isOnLeave && !holiday && !isWeeklyOff;
    const canHover = isStandard || isOnLeave || isOnSpecialShift || holiday;
    const isStandardShiftChanged = isOfficeFloor && isStandard && data.date === data.schedule_start_date;

    const [isOpen, setIsOpen] = useState(false);

    return (
        <Popover placement="bottom-start" open={isOpen} onOpenChange={setIsOpen}>
            <PopoverTrigger asChild onClick={() => canHover && setIsOpen(prev => !prev)}>
                <div
                    className={`shift-value ${isOpen
                        ? "active"
                        : canHover
                            ? "can-hover"
                            : ""
                        }`}
                >
                    <span className={`${isStandard ? "on-standard-shift" : ""} ${isStandardShiftChanged ? 'star' : ''}`}>
                        {isOnSpecialShift
                            ? "Special"
                            : isOnLeave
                                ? "Leave"
                                : holiday
                                    ? "Holiday"
                                    : isWeeklyOff
                                        ? "Weekly Off"
                                        : "Standard"}
                    </span>
                </div>
            </PopoverTrigger>
            <PopoverContent className="popup-fade-in attendance-shift-popup" style={{ top: "3px" }}>
                {isOnSpecialShift ? (
                    <SpecialShiftPopup
                        specialShift={data.special_shift_time}
                        isWFH={data?.special_shift_wfh === 1}
                        activeTracking={data?.special_shift_activity_tracked === 1}
                    />
                ) : holiday ? (
                    <div className="holiday-popup">{holiday}</div>
                ) : isOnLeave ? (
                    <LeaveDetailsPopup leaveDate={data.leave_date} />
                ) : isStandard ? (
                    <ShiftDetailsPopUp
                        userShiftDetails={userShiftDetails}
                        setUserShiftDetails={setUserShiftDetails}
                        resourceId={data.resource_id}
                        date={date}
                    />
                ) : null}
            </PopoverContent>
        </Popover>
    );
};

function SpecialShiftPopup({ specialShift, isWFH, activeTracking, isOnlineWork }) {
    return (
        <div className="special-shift-popup">
            <table className="special-shift-body">
                <tbody>
                    {specialShift.map((ss) => {
                        const formattedStartTime = formatTime(
                            ss.special_shift_start_time.time
                        )
                            .replace("-", "")
                            .replace(":", " : ");
                        const formattedEndTime = formatTime(ss.special_shift_end_time.time)
                            .replace("-", "")
                            .replace(":", " : ");
                        const isNegativeStartTime =
                            ss.special_shift_start_time.time.includes("-");
                        const isNegativeEndTime =
                            ss.special_shift_end_time.time.includes("-");
                        return (
                            <tr
                                className="special-shift-info"
                                key={ss.special_shift_start_time.time}
                            >
                                <td>
                                    <div
                                        className={
                                            isNegativeStartTime
                                                ? "icon-info-bubble-after-wrapper"
                                                : ""
                                        }
                                    >
                                        {formattedStartTime}
                                        {/* {isNegativeStartTime && (
                                                    <MouseTooltip
                                                        asChild
                                                        content={
                                                            <span>
                                                                Clock-In will occur a day before in your selected timezone
                                                            </span>
                                                        }
                                                        style={{ lineHeight: "17px" }}
                                                    >
                                                        <span className="icon-info-bubble-absolute" />
                                                    </MouseTooltip>
                                                )} */}
                                    </div>
                                </td>
                                <td>
                                    <div className="hyphen-wrapper">
                                        <span className="hyphen"></span>
                                    </div>
                                </td>
                                <td>
                                    <div
                                        className={
                                            isNegativeEndTime ? "icon-info-bubble-after-wrapper" : ""
                                        }
                                    >
                                        {formattedEndTime}
                                        {/* {isNegativeEndTime && (
                                                    <MouseTooltip
                                                        asChild
                                                        content={
                                                            <span>
                                                                Clock-Out will occur a day before in your selected timezone
                                                            </span>
                                                        }
                                                        style={{ lineHeight: "17px" }}
                                                    >
                                                        <span className="icon-info-bubble-absolute" />
                                                    </MouseTooltip>
                                                )} */}
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <WorkAt isWFH={isWFH} activeTracking={activeTracking} isOnlineWork={isOnlineWork} />
        </div>
    );
}

function LeaveDetailsPopup({ leaveDate }) {
    return (
        <div className="leave-details-popup">
            {leaveDate.map((leave, index) => {
                return (
                    <div className="info" key={index}>
                        <span>{format(leave.start_date, "ddd, MMM DD")}</span>
                        <span className="hyphen"></span>
                        <span>{format(leave.end_date, "ddd, MMM DD")}</span>
                    </div>
                );
            })}
        </div>
    );
}

function ShiftDetailsPopUp({
    resourceId,
    date,
    userShiftDetails,
    setUserShiftDetails,
}) {
    const formattedDate = format(date, "YYYY-MM-DD");
    const formattedDateWithoutDash = format(date, "YYYYMMDD");

    const { checkIsDSTActive, myTimeZone } = useGlobalContext();
    useEffect(() => {
        const shiftDetails =
            userShiftDetails?.[formattedDateWithoutDash]?.[resourceId];
        const controller = new AbortController();
        async function getCurrentShiftData() {
            try {
                let url = new URL(AL_BASE_URL + `/shift/${resourceId}`);
                url.searchParams.append("date", formattedDate);
                url.searchParams.append("shift_type", "standard");
                url = url.toString();

                const response = await APIService.apiRequest(
                    url,
                    null,
                    false,
                    "GET",
                    controller
                );

                if (response.status === 1) {
                    const tzOffset = myTimeZone.utc_offset;
                    const dstTZOffset = myTimeZone.dst_utc_offset ?? tzOffset;
                    setUserShiftDetails((oldUserShiftDetails) => {
                        const oldUserShiftDetailsCopy =
                            structuredClone(oldUserShiftDetails);
                        // const isDST = response.output.schedule_is_dst[0] === 'Yes';
                        const isDST = checkIsDSTActive(
                            null,
                            new Date(`${formattedDate} 12:00:00`)
                        );
                        const key = isDST ? "dst_schedule" : "schedule";
                        const offset = isDST ? dstTZOffset : tzOffset;
                        oldUserShiftDetailsCopy[formattedDateWithoutDash] = {
                            ...(oldUserShiftDetailsCopy[formattedDateWithoutDash] || {}),
                            [resourceId]: response.output[key].map((shift) => {
                                const [start_time, start_time_offset] =
                                    convertHhmmToUserTimezone(shift.start_time.time, offset);
                                const [end_time, end_time_offset] = convertHhmmToUserTimezone(
                                    shift.end_time.time,
                                    offset
                                );
                                return {
                                    ...shift,
                                    start_time: {
                                        time: start_time,
                                        time_offset: start_time_offset,
                                    },
                                    end_time: {
                                        time: end_time,
                                        time_offset: end_time_offset,
                                    },
                                };
                            }),
                        };

                        // return {};
                        return oldUserShiftDetailsCopy;
                    });
                }
            } catch (error) {
                alertService.error(error.message);
            }
        }

        if (!shiftDetails) getCurrentShiftData();
        return () => controller.abort();
    }, []);

    const shiftData =
        userShiftDetails?.[formattedDateWithoutDash]?.[resourceId];
    const isWFH = shiftData?.some(
        (day) => day?.weekday === getWeek(date) && day?.is_wfh === 1
    ) ?? false;
    let activeTracking = shiftData?.some(
        (day) => day?.weekday === getWeek(date) && day?.is_activity_tracked === 1
    )

    return (
        <div className="user-shift-details">
            <div className="user-shift-content">
                {shiftData ? (
                    <>
                        <StandardShiftDetailCard
                            data={shiftData}
                            label="Standard Shift"
                            trimWeekDay="1"
                            moreSpace={true}
                        />
                        <WorkAt isWFH={isWFH} activeTracking={activeTracking} />
                    </>
                ) : (
                    <div className="shift-loading-wrapper">{<LineLoader />}</div>
                )}
            </div>
        </div>
    );
}

function WorkAt({ isWFH, activeTracking = false }) {
    return (
        <div className="work-at">
            <div className="group" >
                <span className={`location-type ${isWFH ? 'remote' : 'office'}`}></span>
                <span className="label">{isWFH ? 'Remote' : 'Office'}</span>
            </div>
            <div className="group">
                <span className={`work-type ${activeTracking ? 'online' : 'offline'}`}></span>
                <span className="label">{activeTracking ? 'Online' : 'Offline'} work</span>
            </div>
        </div>
    )
}

export default AttendanceValue;
