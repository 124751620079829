import { MouseTooltip } from "components/ToolTip";
import useWindowDimensions from "hooks/useWindowDimensions";
import "./FilterButton.scss";

export default function FilterButton({ onClick, onDoubleClick, filterCount, tooltip }) {
  const isDesktopVersion = useWindowDimensions().width > 640;

  return (
    <MouseTooltip
      asChild
      delay={500}
      show={isDesktopVersion && tooltip && filterCount > 0}
      content={tooltip}
      style={{ lineHeight: "14px" }}
    >
      <button
        className="filter-button"
        onClick={(e) => onClick && onClick(e)}
        onDoubleClick={(e) => onDoubleClick && onDoubleClick(e)}
      >
        <span className="icon icon-filter"></span>
        {filterCount > 0 && <span>{filterCount}</span>}
      </button>
    </MouseTooltip>
  );
}
