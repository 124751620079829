import React, { useState, useEffect, useMemo, useCallback, Fragment } from 'react';

import { useParams } from 'react-router-dom';
import useUserData from 'hooks/useUserData';

import { alertService } from 'services/alertService.js';
import APIService from "services/apiService";

import Avatar from 'components/Avatar';
import NotAvailable from 'components/SuperTabs/NotAvailable';
import { useTabContext } from 'components/SuperTabs/TabContext';

import { copyToClipBoard } from 'utils/Utils';
import { addHttpsToURLString, formatDate, isEmpty } from 'utils/Common';
import { SITE_PREFIX, API_BASE_URL } from 'components/Constants';

import './styles.scss';

// utilities
function replaceBrTag(markup) {
  return markup.replace(/\n/g, '<br/>');
}

function isConfidential(value) {
  return value === 'Confidential' || value === 'confidential';
}

export default function ViewProfile(props) {
  const [activeTab, setActiveTab] = useState('About');
  const [profile, setProfile] = useState(null);
  const controller = new AbortController()

  const { modifyTab, activeSubTabRef } = useTabContext();

  const { isHR } = useUserData();

  const { currentUserData } = props
  const { id: tab_id } = useParams();

  const isAllowedToEdit =
    isHR || (tab_id === currentUserData?.resource_id);

  const skills = useMemo(() => {
    let result = []

    if (profile?.skill_id?.length > 0) {
      result = profile.skill_id
        .sort((a, b) => {
          return a.skill_name.localeCompare(b.skill_name)
        })
    }

    return result
  }, [profile?.skill_id])

  //Setting the state of profile image
  const setProfileImage = async (profileData) => {
    if (profileData && profileData.resource && profileData.resource.img_url) {
      props.profileImage[1](profileData.resource.img_url);
    } else {
      props.profileImage[1](null);
    }
  }

  const fetchProfileData = async () => {
    try {
      props.setLoadingProfile(true);
      const url = API_BASE_URL + `/resource_details/${tab_id}`;
      const response = await APIService.apiRequest(url, null, false, 'GET', controller)

      if (response.status === 1 || (response.output !== undefined && response.output)) {
        if (activeSubTabRef.current.firstName !== response.output.resource.first_name) {
          const tab = {
            firstName: response.output.resource.first_name,
            lastName: response.output.resource.last_name,
            imgSrc: response.output.resource.img_url,
            id: tab_id,
            showAvatar: true,
            showEditButton: parseInt(currentUserData?.resource_id) === response.output.resource.id || 'HR',
            isEditMode: false,
            url: `/profile/${tab_id}`
          }

          modifyTab({ oldTab: activeSubTabRef.current, newTab: tab, isSubTab: true });
        }

        const profileData = response.output;
        await setProfileImage(profileData);
        setProfile({ ...profileData });

        let object = { ...props.setProfile[0] }
        object[SITE_PREFIX + `${tab_id}`] = profileData;

        props.setProfile[1]({ ...object });
        props.showTabEdit[1](isAllowedToEdit);
      } else if (response?.message === 'Resource does not exist') {
        props.setDeletedProfile(prev => [...prev, tab_id]);
      }
    } catch (error) {
      alertService.error(error.message);
    } finally {
      props.setLoadingProfile(false);
    }
  }

  useEffect(() => {
    async function updateData() {
      setProfile(null)
      // setLoadingProfile(true)
      setActiveTab('About')
      props.showTabEdit[1](false);

      let profile_data = props.setProfile[0][SITE_PREFIX + `${tab_id}`];
      if (profile_data && !profile_data.formsubmit) {
        setProfile({ ...profile_data });
        await setProfileImage(profile_data);
        props.showTabEdit[1](isAllowedToEdit);
        // setLoadingProfile(false);
      } else if (profile_data && profile_data.formsubmit) {
        setProfile({ ...profile_data.formsubmit });
        await setProfileImage(profile_data.formsubmit);
        props.showTabEdit[1](isAllowedToEdit);
        // setLoadingProfile(false);
      } else {
        await fetchProfileData(tab_id);
      }
    }

    updateData();

    return () => {
      controller.abort()
    }
  }, [tab_id]);//eslint-disable-line

  const formatProfileName = useCallback(({ first_name, middle_name, last_name }) => {
    let name = first_name

    if (middle_name) {
      name += ` ${middle_name}`
    }

    if (last_name) {
      name += ` ${last_name}`
    }

    return name
  }, [])

  if (props.deletedProfile.includes(tab_id)) {
    return (
      <NotAvailable subTabName='Profile' />
    )
  }

  return (
    <div id='profile-page'>
      <div id='view-wrapper' className={`container ${(isHR || tab_id === props.currentUserData?.resource_id) ? 'can-edit' : ''}`}>
        {!props.loadingProfile && profile && (
          <>
            <div className='fields-section personal'>
              <div className='profile-info-top'>
                <div className='profile-pic'>
                  <Avatar
                    imgSrc={props.profileImage[0] ?? null}
                    alt={`${profile.resource.first_name}'s pic`}
                    firstName={profile.resource.first_name}
                    lastName={profile.resource.last_name}
                    height={'100%'}
                    width={'100%'}
                    fontSize={50}
                    borderRadius={'2%'}
                  />
                </div>
                <div className='profile-name-wrapper'>
                  <div>
                    <h3 className='name'>{formatProfileName(profile.resource)}</h3>
                    <div className='country'>
                      {props?.country?.length > 0 &&
                        profile?.address?.length > 0 &&
                        profile?.address[0]?.state &&
                        profile.address[0].state +
                        (props.country[profile.address[0].country_id - 1] !== 'N/A'
                          ? `, ${props.country[profile.address[0].country_id - 1]}`
                          : '')}
                    </div>
                  </div>
                  <ul className='social'>
                    <li>
                      {profile?.resource?.phone && !isConfidential(profile?.resource?.phone) ? (
                        <button
                          className='phone'
                          onClick={(e) => {
                            e.stopPropagation()
                            copyToClipBoard(profile.resource.phone)
                          }}
                        ></button>
                      ) : (
                        <button className='phone-fade'></button>
                      )}
                    </li>

                    <li>
                      {profile?.resource?.email && !isConfidential(profile?.resource?.email) ? (
                        <a
                          href={'mailto:' + profile.resource.email}
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                        >
                          <button className='email'></button>
                        </a>
                      ) : (
                        <button className='email-fade'></button>
                      )}
                    </li>

                    <li>
                      {profile?.resource?.linkedin ? (
                        <a
                          href={addHttpsToURLString(profile.resource.linkedin)}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <button className='linkedin'></button>
                        </a>
                      ) : (
                        <button className='linkedin-fade'></button>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
              <div className='profile-info-bottom'>
                <div className='info'>
                  <div className='label'>DOB</div>
                  <div className='value'>
                    {profile?.resource?.date_of_birth
                      ? formatDate(new Date(profile.resource.date_of_birth))
                      : 'No Data'}
                  </div>
                </div>
                <div className='info'>
                  <div className='label'>Gender</div>
                  <div className='value capitalize'>{profile?.resource?.gender || 'No Data'}</div>
                </div>
                <div className='info'>
                  <div className='label'>Languages</div>
                  <div className='value'>{profile?.resource?.languages || 'No Data'}</div>
                </div>
              </div>
            </div>
            <div className='fields-section detail'>
              <div className='profile-info-tab'>
                <ul className='tabbar'>
                  <li>
                    <button id='About' onClick={() => setActiveTab('About')}>
                      About
                    </button>
                    {activeTab === 'About' ? <hr></hr> : ''}
                  </li>
                  <li>
                    <button id='Skills' onClick={() => setActiveTab('Skills')}>
                      Skills
                    </button>
                    {activeTab === 'Skills' ? <hr></hr> : ''}
                  </li>
                  <li>
                    <button id='Education' onClick={() => setActiveTab('Education')}>
                      Education
                    </button>
                    {activeTab === 'Education' ? <hr></hr> : ''}
                  </li>
                  <li>
                    <button id='WorkExperience' onClick={() => setActiveTab('WorkExperience')}>
                      Experience
                    </button>
                    {activeTab === 'WorkExperience' ? <hr></hr> : ''}
                  </li>
                  <li>
                    <button id='ContactInfo' onClick={() => setActiveTab('ContactInfo')}>
                      Contact
                    </button>
                    {activeTab === 'ContactInfo' ? <hr></hr> : ''}
                  </li>
                  <li>
                    <button id='Family' onClick={() => setActiveTab('Family')}>
                      Family
                    </button>
                    {activeTab === 'Family' ? <hr></hr> : ''}
                  </li>
                </ul>
                <div className='profile-tab-details'>
                  {activeTab === 'About' ? (
                    <About about={profile?.resource?.about_resource} />
                  ) : activeTab === 'Skills' ? (
                    <Skills skills={skills} />
                  ) : activeTab === 'Education' ? (
                    <Education education={profile?.education} />
                  ) : activeTab === 'WorkExperience' ? (
                    <WorkExperience work_experience={profile?.work_experience} />
                  ) : activeTab === 'ContactInfo' ? (
                    <ContactInfo
                      profileId={profile?.id}
                      resource={profile?.resource}
                      address={profile?.address}
                      country={props?.country}
                    />
                  ) : activeTab === 'Family' ? (
                    <Family family={profile?.resource?.family} />
                  ) : null}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

// Profile Components

function About({ about }) {
  return (
    <>
      {about ? (
        <div
          className='tab-inner about-para'
          dangerouslySetInnerHTML={{ __html: replaceBrTag(about) }}
        >
        </div>
      ) : (
        <div className='tab-inner-nodata'>
          <h2>
            <i>No Data</i>
          </h2>
        </div>
      )}
    </>
  );
}

function Skills({ skills }) {
  return (
    <>
      {skills?.length > 0 ? (
        <div className='tab-inner skills-list'>
          {skills.map((skill, i) => (
            <label key={i} className='skill-box'>
              {skill.skill_name}
            </label>
          ))}
        </div>
      ) : (
        <div className='tab-inner-nodata'>
          <h2>
            <i>No Data</i>
          </h2>
        </div>
      )}
    </>
  );
}

function Education({ education }) {
  return (
    <>
      {education?.length > 0 ? (
        education.map((sub_education, i) => {
          const isSubEducationDeleted = !sub_education.is_deleted || sub_education.is_deleted === 0;

          return (
            <div className={isSubEducationDeleted ? 'tab-inner' : ''} key={i}>
              <div className='fields-group'>
                <h3 className='name'>
                  {sub_education.name === 'Unrecognized text style' ? (
                    <span className='name-unrecognized'>
                      Unrecognized text style (Please edit it manually)
                    </span>
                  ) : (
                    sub_education.name
                  )}
                </h3>
                <div className='info-wrapper'>
                  {sub_education.degree && (
                    <div className='info'>
                      <div className='label'>Degree</div>
                      <div className='value'>{sub_education.degree}</div>
                    </div>
                  )}
                  {sub_education.start_date && sub_education.end_date && (
                    <div className='info'>
                      <div className='label'>Period</div>
                      <div className='value'>
                        {sub_education.start_date} - {sub_education.end_date}
                      </div>
                    </div>
                  )}
                  {sub_education.field && (
                    <div className='info'>
                      <div className='label'>Field</div>
                      <div className='value'>{sub_education.field}</div>
                    </div>
                  )}
                  {sub_education.score && (
                    <div className='info'>
                      <div className='label'>Score</div>
                      <div className='value'>{sub_education.score}</div>
                    </div>
                  )}
                  {sub_education.highlights && (
                    <div className='info'>
                      <div className='label'>Highlights</div>
                      <div
                        className='value text-para'
                        dangerouslySetInnerHTML={{ __html: replaceBrTag(sub_education.highlights), }}
                      ></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className='tab-inner-nodata'>
          <h2>
            <i>No Data</i>
          </h2>
        </div>
      )}
    </>
  );
}

function WorkExperience({ work_experience }) {
  const months = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec',
    '1': 'Jan',
    '2': 'Feb',
    '3': 'Mar',
    '4': 'Apr',
    '5': 'May',
    '6': 'Jun',
    '7': 'Jul',
    '8': 'Aug',
    '9': 'Sep',
  };

  return (
    <>
      {work_experience?.length > 0 ? (
        work_experience.map((sub_work_experience, i) => (
          <div
            className={sub_work_experience && sub_work_experience.company_name ? 'tab-inner' : ''}
            key={i}
          >
            <div className='fields-group'>
              <h3 className='name'>{sub_work_experience.company_name}</h3>
              {sub_work_experience?.tenure?.map((sub_tenure, j) => (
                <div className='sub' key={j}>
                  <div className='fields-group'>
                    <div className='info-wrapper'>
                      {sub_tenure.designation && (
                        <div className='info'>
                          <div className='label'>Designation</div>
                          <div className='value'>{sub_tenure.designation}</div>
                        </div>
                      )}
                      {sub_tenure.employment_type && (
                        <div className='info'>
                          <div className='label'>Employment Type</div>
                          <div className='value'>{sub_tenure.employment_type}</div>
                        </div>
                      )}
                      {sub_tenure.location && (
                        <div className='info'>
                          <div className='label'>Location</div>
                          <div className='value'>{sub_tenure.location}</div>
                        </div>
                      )}
                      {sub_tenure.start_year && sub_tenure.end_year && (
                        <div className='info'>
                          <div className='label'>Period</div>
                          <div className='value'>
                            {(sub_tenure.start_month && sub_tenure.start_month !== '0'
                              ? months[sub_tenure.start_month.toString()] + ', '
                              : '') + sub_tenure.start_year}{' '}
                            -{' '}
                            {(sub_tenure.end_month && sub_tenure.end_month !== '0'
                              ? months[sub_tenure.end_month.toString()] + ', '
                              : '') + sub_tenure.end_year}
                          </div>
                        </div>
                      )}
                      {sub_tenure.start_year && !sub_tenure.end_month && !sub_tenure.end_year && (
                        <div className='info'>
                          <div className='label'>Period</div>
                          <div className='value'>
                            {`${sub_tenure.start_month && sub_tenure.start_month !== '0' ? months[sub_tenure.start_month.toString()] + ',' : ''} ${sub_tenure.start_year} - Present`}
                          </div>
                        </div>
                      )}
                      {sub_tenure.work_description && (
                        <div className='info'>
                          <div className='label'>Description</div>
                          <div
                            className='value text-para'
                            dangerouslySetInnerHTML={{
                              __html: replaceBrTag(sub_tenure.work_description),
                            }}
                          ></div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))
      ) : (
        <div className='tab-inner-nodata'>
          <h2>
            <i>No Data</i>
          </h2>
        </div>
      )}
    </>
  );
}

function ContactInfo({ resource, address, country }) {

  const formatAddress = useCallback((residence) => {
    const { street_address, city, state, country_id, zipcode, is_permanent, is_present } = residence
    let result = ''

    if (street_address) {
      result = result + street_address
    }

    if (city) {
      result = result + `${result ? ', ' : ''}${city}`
    }

    if (state) {
      result = result + `${result ? ', ' : ''}${state}`
    }

    if (country[country_id - 1] !== 'N/A') {
      result = result + `${result ? ', ' : ''}${country[country_id - 1]}`
    }

    if (zipcode) {
      result = result + `${result ? ', ' : ''}${zipcode}`
    }

    if (result.trim().length) {
      result = result + '.' // add full stop at the end

      if (+is_permanent) {
        result = result + ' (Permanent Address)'
      }

      if (+is_present) {
        result = result + ' (Current Address)'
      }
    }

    return result
  }, [country])

  return (
    <>
      {!resource ? (
        <div className='tab-inner-nodata'>
          <h2>
            <i>No Data</i>
          </h2>
        </div>
      ) : (
        <div className='tab-inner contact'>
          <div className='info-wrapper'>
            {/* Secondary Phone */}
            <div className='info'>
              <div className='label'>Phone</div>
              <div className='value'>
                {!isConfidential(resource?.secondary_phones) &&
                  resource?.secondary_phones?.length > 0
                  ? resource.secondary_phones.map((phone, i) => {
                    return (
                      <div key={i} className='value-value'>
                        <span>{phone}</span>
                        <span
                          onClick={() => copyToClipBoard(phone)}
                          className='btn-copy'
                          title='Copy to clipboard'
                        ></span>
                      </div>
                    )
                  })
                  : isEmpty(resource?.secondary_phones)
                    ? 'No Data'
                    : resource?.secondary_phones}
              </div>
            </div>

            {/* Secondary Email */}
            <div className='info'>
              <div className='label'>Email</div>
              {resource?.email && !isConfidential(resource?.email) ? (
                <div className='value'>
                  <div className='value-value'>
                    <a href={`mailto:${resource.email}`}>{resource.email}</a>
                    <span
                      onClick={() => copyToClipBoard(resource.email)}
                      className='btn-copy'
                      title='Copy to clipboard'
                    ></span>
                  </div>
                </div>
              ) : resource?.secondary_emails?.length > 0 && !isConfidential(resource?.secondary_emails) ? (
                <div>
                  {resource.secondary_emails.map((email, i) => {
                    return (
                      <Fragment key={i}>
                        <div className='value'>
                          <div className='value-value'>
                            <a href={`mailto:${email}`}>{email}</a>
                            <span
                              onClick={() => copyToClipBoard(email)}
                              className='btn-copy'
                              title='Copy to clipboard'
                            ></span>
                          </div>
                        </div>
                      </Fragment>
                    )
                  })}
                </div>
              ) : (
                <div className='value'>
                  No Data
                </div>
              )}
            </div>

            {/* Adress */}
            <div className='info address'>
              <div className='label'>Address</div>
              <div className='value'>
                {!isConfidential(resource?.address) && address?.length > 0
                  ? address.map((residence, i) => (
                    <div key={i} className='address-box'>
                      {formatAddress(residence)}
                    </div>
                  ))
                  : isEmpty(resource?.address)
                    ? 'No Data'
                    : resource?.address}
              </div>
            </div>
            {typeof resource?.social === 'object' && (
              <>
                {resource.social?.git && (
                  <div className='info'>
                    <div className='label'>Git Repository</div>
                    <div className='value'>
                      <div className='value-value'>
                        <a href={resource.social.git} target='__blank'>{resource.social.git}</a>
                        <span
                          onClick={() => copyToClipBoard(resource.social.git)}
                          className='btn-copy'
                          title='Copy to clipboard'
                        ></span>
                      </div>
                    </div>
                  </div>
                )}
                {resource.social?.portfolio && (
                  <div className='info'>
                    <div className='label'>Portfolio</div>
                    <div className='value'>
                      <div className='value-value'>
                        <a href={resource.social.portfolio} target='__blank'>{resource.social.portfolio}</a>
                        <span
                          onClick={() => copyToClipBoard(resource.social.portfolio)}
                          className='btn-copy'
                          title='Copy to clipboard'
                        ></span>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}

function Family({ family }) {
  return (
    <>
      {!isConfidential(family) && family?.length > 0 ? (
        <div className='tab-inner family'>
          <table>
            <thead>
              <tr>
                <th className='family-table-head'>Name</th>
                <th className='family-table-head'>Relation</th>
                <th className='family-table-head'>Occupation</th>
                <th className='family-table-head'>Phone</th>
              </tr>
            </thead>
            <tbody>
              {family.map((member, i) => (
                <tr key={i}>
                  <td>{member.member}</td>
                  <td>{member.relation}</td>
                  <td>{member.occupation}</td>
                  <td>
                    <div className='display-wrapper'>
                      <label className='skill-box'>
                        {member.phone}
                        <span
                          className='btn-copy'
                          title='Copy to clipboard'
                          onClick={(e) => {
                            copyToClipBoard(member.phone)
                          }}
                        ></span>
                      </label>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className='tab-inner-nodata'>
          <h2>
            <i>{isEmpty(family) ? 'No Data' : family}</i>
          </h2>
        </div>
      )}
    </>
  )
}