import React, { useState, useCallback, useRef, useEffect, useLayoutEffect } from 'react'
// components
import SpeedList from 'components/SpeedList';
import ClickOutsideListner from 'components/ClickOutsideListner';
import Avatar from 'components/Avatar';
import MemoryUsage from './MemoryUsage';
import Accordion from 'components/Accordion';
// import AddNotificationForm from './AddNotificationForm';
// context
import { useGlobalContext } from 'contexts/GlobalContext';
import DownloadContent from './DownloadContent';
// hooks
import useWindowDimensions from "hooks/useWindowDimensions";
// utils
import { isDev, isElectron, formatDate, getTimeZonesFromLocal, getUser, removeUserSession, isElectronApp } from "utils/Common";
import { API_BASE_URL, SITE_PREFIX, SITE_VERSION } from "../Constants.js";
// store
import { takeBreak, useTimerStore } from 'components/Timer/store';
// services
import APIService from "services/apiService";
import { alertService } from 'services/alertService';
// styles
import './ControlPanel.scss'

const ControlPanel = ({ hasTimerAccess }) => {
    const { organizationsList, selectedOrganization, handleSelectedOrganizationChange, myTimeZone, handleMyTimeZoneChange } = useGlobalContext();

    const getTimerToken = () => {
        const selectedOrgId = selectedOrganization.id;
        const timerOrg = organizationsList.find(org => +org.is_timer === 1);
        if (selectedOrgId === timerOrg?.id) return;
        const timerToken = localStorage.getItem(SITE_PREFIX + 'timer_token')
        return `Bearer ${timerToken}`;
    }

    const { width } = useWindowDimensions();
    const isDesktopVersion = width > 640;

    const isProd = !isDev();
    const devPrefix = isProd ? '' : 'Dev-'

    const latestAppVersion = isProd ? '2.1.1' : '2.1.1';
    const latestAppReleaseDate = isProd ? 'Sep 22, 2024' : 'Sep 20, 2024';

    const userAgent = window.navigator.userAgent.toLowerCase();
    const isBrowser = !isElectron();
    const isWindowsElectron = !isBrowser && userAgent.indexOf('win') !== -1;
    const isMacElectron = !isBrowser && userAgent.indexOf('mac') !== -1;

    const timeZones = getTimeZonesFromLocal();

    // const userDetails = getUser();
    // const hasHRPrivilege = userDetails.privileges.hr?.some(p => p.includes('HR'));
    // const canAddNotification = userDetails.privileges.employee?.some(p => p.includes('THINKTEAM_ENGINEER'));

    const downloadContent = [
        {
            name: 'Plugins',
            files: [
                {
                    description: 'Plugin to send LinkedIn profile data to TeamLink, when activated on someone\'s LinkedIn profile.',
                    id: 1,
                    name: 'TeamLink Profile',
                    releaseDate: isProd ? 'Sep 14, 2024' : 'Sep 13, 2024',
                    version: '2.0.5',
                    buttons: [
                        {
                            name: 'Download',
                            downloadLink: `https://teamlink-assets.s3.us-east-2.amazonaws.com/${devPrefix.toLowerCase()}teamlink-profile-plugin-v2.0.5.zip`
                        },
                    ]
                }
            ]
        },
        {
            name: 'Application',
            files: [
                {
                    description: `Release on ${latestAppReleaseDate}`,
                    display: isBrowser,
                    id: 1,
                    name: 'TeamLink',
                    releaseDate: latestAppReleaseDate,
                    version: latestAppVersion,
                    buttons: [
                        {
                            name: 'Download (Intel chip)',
                            downloadLink: `https://${!isProd ? 'development-' : ''}app-install.teamlink.com/download/flavor/${devPrefix}TeamLink/${latestAppVersion}/osx_64`,
                            arch: 'x64'
                        },
                        {
                            name: 'Download (Apple chip)',
                            downloadLink: `https://${!isProd ? 'development-' : ''}app-install.teamlink.com/download/flavor/${devPrefix}TeamLink/${latestAppVersion}/osx_arm64`,
                            arch: 'arm64'
                        }
                    ]
                }
            ]
        },
        {
            name: 'Support',
            files: [
                {
                    description: `Release on May 10, 2023`,
                    display: true,
                    id: 1,
                    name: 'iAssist',
                    releaseDate: 'May 10, 2023',
                    version: '1.0.0',
                    buttons: [
                        {
                            name: 'Download (Intel chip)',
                            arch: 'x64'
                        },
                        {
                            name: 'Download (Apple chip)',
                            arch: 'arm64'
                        }
                    ]
                }
            ]
        }
    ]

    const [showControlPanel, setShowControlPanel] = useState(false);
    const [appVersion, setAppVersion] = useState('unknown');
    // const [osArchitecture, setOsArchitecture] = useState('');
    const [expandedOption, setExpandedOption] = useState('');
    const [systemNotification, setSystemNotification] = useState(null);
    const [expandedNotification, setExpandedNotification] = useState(null);
    const [selectedOrg, setSelectedOrg] = useState(selectedOrganization);
    // const [organizationsList, setOrganizationsList] = useState(null);
    const [theme, setTheme] = useState(() => {
        let settings = localStorage.getItem(SITE_PREFIX + "settings");
        if (settings) settings = JSON.parse(settings);
        return settings?.theme ? settings.theme : 'dark';
    });
    // const [showNewNotificationForm, setShowNewNotificationForm] = useState(false);
    // const [isDarkTheme, setIsDarkTheme] = useState(true);
    const [readNotificationsList, setReadNotificationsList] = useState([]);

    const notificationRefs = useRef({});

    useLayoutEffect(() => {
        const mediaQuery = window?.matchMedia('(prefers-color-scheme: dark)');
        let _theme = theme;
        const handleThemeChange = (e) => {
            let theme = e.matches ? 'dark' : 'light';
            document.body.dataset.theme = theme;
            !isBrowser && window.ipcRender.send('message:updateTheme', theme);
        }

        if (theme === 'system') {
            _theme = mediaQuery?.matches ? 'dark' : 'light';
            mediaQuery?.addEventListener('change', handleThemeChange);
        }
        document.body.dataset.theme = _theme;
        !isBrowser && window.ipcRender.send('message:updateTheme', _theme);

        return () => {
            mediaQuery?.removeEventListener('change', handleThemeChange);
        }
    }, [isBrowser, theme])

    useEffect(() => {
        async function getContent() {
            let details = await window.ipcRender.invoke('getAppVersionAndArch');
            setAppVersion(details.version);
            // setOsArchitecture(details.arch);
        }
        !isBrowser && getContent();
        const abortController = new AbortController();
        getSystemNotification({ controller: abortController });

        return () => {
            abortController.abort();
        }
    }, [isBrowser])

    const getSystemNotification = ({ controller } = {}) => {
        APIService.apiRequest(API_BASE_URL + '/system_notification', null, false, 'GET', controller)
            .then(response => {
                if (response.status === 1) {
                    setSystemNotification(response.output);
                } else {
                    console.log('getSystemNotification else', response);
                    if (response instanceof DOMException && response.name === 'AbortError') return;
                    alertService.error(response.msg);
                }
            })
            .catch(err => {
                console.log('getSystemNotification catch', err);
                alertService.error(err.message);
            });
    }

    const updateTimeZone = (timeZone) => {
        const apiPayload = { timezone_id: timeZone.id }
        APIService.apiRequest(API_BASE_URL + '/attendance/timezone', apiPayload, false, 'PUT')
            .then(response => {
                if (response.status === 1) {
                    handleMyTimeZoneChange(timeZone);
                    alertService.success('Your time zone has changed. To see the new time zone setting, please reload the page.');
                } else {
                    alertService.error(response.msg);
                }
            })
            .catch(err => {
                alertService.warning(err.message);
            });
    }

    const getTimerStatus = (timeZone) => {
        const timerToken = getTimerToken();
        APIService.apiRequest(API_BASE_URL + '/attendance/timer', null, false, 'GET', null, timerToken, !!timerToken)
            .then(response => {
                if (response.status === 1) {
                    return response;
                } else {
                    alertService.error(response.msg);
                }
            })
            .then((res) => {
                if (res.state === 'Off') {
                    updateTimeZone(timeZone);
                } else {
                    alertService.warning('Please stop your timer first before updating the timezone');
                }
            })
            .catch(err => {
                alertService.error(err.message);
            });
    }

    const handleLogout = () => {
        const { isTimerStarted } = useTimerStore.getState();
        const shouldStopTimer = hasTimerAccess && isTimerStarted;

        if (shouldStopTimer) {
            alertService.warning('Stopping Timer');
            takeBreak({}, () => logout());
        } else {
            logout();
        }
    };

    const logout = () => {
        removeUserSession();
        if (isElectronApp) window.ipcRender.send("message:logoutSuccessful");
        window.location.href = "/login";
    };

    const markNotificationAsReadOnBackend = (notificationId) => {
        APIService.apiRequest(API_BASE_URL + `/read_system_notification/${notificationId}`, null, false, 'PUT')
            .then(response => {
                if (response.status === 1) {
                    setReadNotificationsList(oldReadNotificationsList => [...oldReadNotificationsList, notificationId]);
                } else {
                    console.log('markNotificationAsReadOnBackend else', response)
                    alertService.error(response.msg);
                }
            })
            .catch(err => {
                console.log('markNotificationAsReadOnBackend catch', err)
                alertService.error(err.message);
            });
    }

    const markNotificationAsReadOnFrontend = () => {
        readNotificationsList.forEach(notificationId => {
            setSystemNotification(oldSystemNotification => {
                const filteredArray = oldSystemNotification.unread_count_ids.filter(subArray => {
                    return !subArray.includes(+notificationId);
                });
                return { ...oldSystemNotification, unread_count: oldSystemNotification.unread_count - 1, unread_count_ids: filteredArray }
            });
        });
        setReadNotificationsList([]);
    }

    const isInViewport = useCallback((el) => {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }, [])

    const onSystemNotificationClick = () => {
        if (expandedOption !== 'notification') {
            setTimeout(() => {
                addNotificationAsRead();
            }, 0);
            const notificationId = systemNotification.unread_count_ids?.[0]?.[0];
            if (notificationId) markNotificationAsReadOnBackend(notificationId);
        } else {
            if (readNotificationsList.length > 0) markNotificationAsReadOnFrontend();
        }
        expandOption('notification')
    }

    const toggleControlPanel = (val) => {
        setShowControlPanel(val);
        !val && setExpandedOption('');
        // if (showNewNotificationForm) setShowNewNotificationForm(false);
        if (readNotificationsList.length > 0) markNotificationAsReadOnFrontend();
    }

    const expandOption = useCallback((option) => {
        setExpandedOption((prev) => prev === option ? '' : option);
    }, []);

    // const toggleNewNotificationForm = useCallback(() => {
    //     setShowNewNotificationForm(prevValue => !prevValue);
    // }, []);

    // const updateElectronApp = useCallback(() => {
    //     window.ipcRender.send('message:checkForUpdates');
    // }, []);

    const getNotificationCount = useCallback(() => {
        return systemNotification?.unread_count[0];
    }, [systemNotification])

    const addNotificationAsRead = useCallback(() => {
        for (const [key, value] of Object.entries(notificationRefs.current)) {
            let isUnRead = systemNotification.unread_count_ids.some(subArray => {
                return subArray.includes(+key);
            });

            if (isInViewport(value) && isUnRead && !readNotificationsList.includes(key)) {
                setReadNotificationsList(oldReadNotificationsList => [...oldReadNotificationsList, key]);
            }
        }
    }, [isInViewport, readNotificationsList, systemNotification?.unread_count_ids])

    // const getDownloadUrl = useCallback((appName, arch, version) => {
    //     return `https://${devPrefix}app-install.teamlink.com/download/flavor/${devPrefix}${appName}/${version}/osx_${arch}`
    //     // return `https://brhma.io/download/flavor/${isProd ? '' : 'Dev-'}${appName}/latest/${arch}`
    // }, [devPrefix]);

    // const updateTheme = useCallback((theme) => {
    //     const settings = localStorage.getItem(SITE_PREFIX + 'settings');
    //     let settingsObj = {};
    //     if (settings) {
    //         settingsObj = JSON.parse(settings);
    //     }
    //     settingsObj.theme = theme;
    //     localStorage.setItem(SITE_PREFIX + 'settings', JSON.stringify(settingsObj));
    //     setTheme(theme);
    // }, []);

    const user = getUser();

    const hasThinkTeamAppPrivilege = user.privileges?.employee?.some(p => p.includes('THINKTEAM_DESKTOP_APP'));
    const hasIAssistAppPrivilege = user.privileges?.employee?.some(p => p.includes('IASSIST_DESKTOP_APP'));
    const hasThinkTeamPluginPrivilege = user.privileges?.employee?.some(p => p.includes('THINKTEAM_LINKEDIN_PLUGIN'));
    const hasManagerPrivilege = user.privileges?.employee?.some(p => p.includes('MANAGER'));
    const hasEngineerPrivilege = user.privileges?.employee?.some(p => p.includes('THINKTEAM_ENGINEER'));
    const userName = `${user.resource.first_name}${user.resource.middle_name ? ` ${user.resource.middle_name}` : ''}${user.resource.last_name ? ` ${user.resource.last_name}` : ''}`;

    return (
        <div id='new-control-panel-wrapper'>
            <button
                className={`btn-kebab-wrapper ${!isDesktopVersion ? 'mobile-menu' : ''}`}
                onClick={() => toggleControlPanel(true)}
                title={getNotificationCount() > 0 ? 'You have a notification' : ''}
            >
                <span className={`btn-kebab ${getNotificationCount() > 0 ? 'notification-dot' : ''}`}></span>
            </button>

            <ClickOutsideListner onOutsideClick={() => { toggleControlPanel(false) }}>
                <div className={'control-panel-content-wrapper' + (showControlPanel ? ' open' : '')}>
                    <div id='control-panel-header'>
                        {/* <div title={`${user.first_name}${user.middle_name ? ` ${user.middle_name}` : ''}${user.last_name ? ` ${user.last_name}` : ''}`}>
                        </div> */}
                        <Avatar
                            firstName={user.resource.first_name}
                            lastName={user.resource.last_name}
                            imgSrc={user.resource.img_url}
                            borderRadius={2}
                            height={22}
                            width={22}
                            fontSize={11}
                        />
                        <span className='panel-title'>{userName}</span>
                    </div>
                    <div className='control-panel-body' onScroll={() => expandedOption === 'system-notifications' && addNotificationAsRead()}>
                        <div className='speedlist-accordion'>
                            <Accordion
                                label={<>
                                    <span className='control-panel-label'>Organization</span>
                                    <span>{selectedOrg?.name}</span>
                                </>}
                                id='organization'
                                isExpanded={expandedOption === 'organization'}
                                onExpand={() => expandOption('organization')}
                                disabled={organizationsList.length <= 1}
                            >
                                <div className='speed-select-wrapper'>
                                    <SpeedList
                                        // options, selectedOption, inputFocus, onSelect, hasGroups, position
                                        options={organizationsList}
                                        inputFocus={true}
                                        selectedOption={selectedOrg}
                                        visible={expandedOption === 'organization'}
                                        onSelect={(e) => {
                                            if (e.id === selectedOrg.id) return;
                                            handleSelectedOrganizationChange(e);
                                            setSelectedOrg(e);
                                        }}
                                    />
                                </div>
                            </Accordion>
                        </div>
                        <Accordion
                            label={<>
                                <span>System Notification</span>
                                {getNotificationCount() > 0 && <span className='notification-count'></span>}
                            </>}
                            id='notification'
                            isExpanded={expandedOption === 'notification'}
                            onExpand={onSystemNotificationClick}
                        >
                            <div className='options-content-wrapper'>
                                {!isBrowser && expandedOption === 'notification' && (hasManagerPrivilege || hasEngineerPrivilege) && (
                                    <div className='option-content'>
                                        <MemoryUsage />
                                    </div>
                                )}
                                {
                                    systemNotification?.sn.map((notification) => {
                                        const isNotificationUnRead = systemNotification.unread_count_ids.some(subArray => {
                                            return subArray.includes(+notification.id);
                                        });
                                        const shouldExpand = expandedNotification === notification.id || isNotificationUnRead;
                                        return (
                                            <div
                                                className={'option-content' + (isNotificationUnRead ? ' unread' : '')}
                                                key={notification.id} data-id={notification.id}
                                                ref={(el) => (notificationRefs.current[notification.id] = el)}
                                                onClick={() => setExpandedNotification(prev => prev === notification.id ? null : notification.id)}
                                                title={expandedNotification === notification.id ? 'Click to collapse' : !isNotificationUnRead ? 'Click to expand' : ''}
                                            >
                                                <div className='option-content-header'>
                                                    <div className='control-panel-heading'>{notification.header}</div>
                                                    <span className='control-panel-label'>{formatDate(new Date(notification.created_at.replace(' GMT', '')))}</span>
                                                </div>
                                                <div className={`option-content-body ${shouldExpand ? 'expanded' : ''}`}>
                                                    <div dangerouslySetInnerHTML={{ __html: notification.notification.replace(/\n/g, "<br/>") }}></div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )
                                }
                                {(!systemNotification || systemNotification?.sn?.length === 0) && <div className='center'>No data to display</div>}
                            </div>
                        </Accordion>
                        {(hasThinkTeamPluginPrivilege || hasThinkTeamAppPrivilege || hasIAssistAppPrivilege) && (
                            <Accordion
                                label='Download'
                                id='download'
                                isExpanded={expandedOption === 'download'}
                                onExpand={() => expandOption('download')}
                            >
                                <DownloadContent
                                    content={downloadContent}
                                    hasThinkTeamPluginPrivilege={hasThinkTeamPluginPrivilege}
                                    hasThinkTeamAppPrivilege={hasThinkTeamAppPrivilege}
                                    hasIAssistAppPrivilege={hasIAssistAppPrivilege}
                                />
                            </Accordion>
                        )}
                        {/* <div className='accordion'>
                            <button type='button' className='accordion-btn' onClick={() => expandOption('theme')}>
                                <span className='accordion-label'>Theme</span>
                            </button>
                            {expandedOption === 'theme' && <div className='accordion-content'>

                                <div className='theme-content-wrapper'>
                                    <div className="option radio">
                                        <input
                                            type="radio"
                                            id="dark-theme"
                                            name="theme"
                                            value="dark-theme"
                                            checked={theme === 'dark'}
                                            onChange={() => updateTheme('dark')}
                                        />
                                        <label htmlFor="dark-theme">Dark</label>
                                    </div>
                                    <div className="option radio">
                                        <input
                                            type="radio"
                                            id="light-theme"
                                            name="theme"
                                            value="light-theme"
                                            checked={theme === 'light'}
                                            onChange={() => updateTheme('light')}
                                        />
                                        <label htmlFor="light-theme">Light</label>
                                    </div>
                                    <div className="option radio">
                                        <input
                                            type="radio"
                                            id="system-theme"
                                            name="theme"
                                            value="system-theme"
                                            checked={theme === 'system'}
                                            onChange={() => updateTheme('system')}
                                        />
                                        <label htmlFor="system-theme">System</label>
                                    </div>
                                </div>
                            </div>}
                        </div> */}
                        <Accordion
                            label={<>
                                <span className='control-panel-label'>Timezone</span>
                                <span>{myTimeZone.name}</span>
                            </>}
                            id='time-zone'
                            isExpanded={expandedOption === 'time-zone'}
                            onExpand={() => expandOption('time-zone')}
                        >
                            <div className='speed-select-wrapper'>
                                <SpeedList
                                    // options, selectedOption, inputFocus, onSelect, hasGroups, position
                                    options={timeZones}
                                    inputFocus={true}
                                    selectedOption={myTimeZone}
                                    visible={expandedOption === 'time-zone'}
                                    onSelect={(e) => {
                                        if (e.id === myTimeZone.id) return;
                                        const hasTimerAccess = organizationsList.some(org => +org.is_timer === 1);
                                        if (hasTimerAccess) {
                                            getTimerStatus(e);
                                        } else {
                                            updateTimeZone(e);
                                        }
                                    }}
                                />
                            </div>
                        </Accordion>
                    </div>
                    <div className='control-panel-footer'>
                        <button className='btn-logout' onClick={handleLogout}>Logout</button>
                        <div className='icons-wrapper'>
                            <div className='support-wrapper'>
                                <a href='mailto:isupport@teamlink.com'>
                                    <span className='icon icon-support'></span>
                                </a>
                                <span className='support-tooltip'>isupport@teamlink.com</span>
                            </div>
                            <div className='site-details-wrapper'>
                                <div className={`icon ${isMacElectron ? 'icon-apple' : isWindowsElectron ? 'icon-windows' : 'icon-web'}`}></div>
                                <div className='details-tooltip-wrapper'>
                                    <div className='details-tooltip'>
                                        <div className={`site-logo ${isDev() ? 'dev' : ''}`}></div>
                                        <div>Web version : {SITE_VERSION}</div>
                                        {!isBrowser && <div>App version : {appVersion}</div>}
                                        <div className='control-panel-label'>Copyright 2022 - {new Date().getFullYear()} TeamLink.<br />All rights reserved.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ClickOutsideListner>
        </div>
    )
}

export default ControlPanel