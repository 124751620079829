import { forwardRef, useState } from "react";
import "./SearchInput.scss";

/**
 * @typedef {object} SearchInputProps
 * @property {string} value
 * @property {function} onChange
 * @property {boolean=} expand
 * @property {function=} setExpand
 * @property {number=} blurDelay
 * @property {React.RefObject<HTMLInputElement>=} ref
 */

/**
 * @type {React.FC<SearchInputProps & React.InputHTMLAttributes<HTMLInputElement>>}
 */
const SearchInput = forwardRef(
  ({ expand, setExpand, value, onChange, onClear, blurDelay = 0, showCloseBtn = false, keepExpanded = false, ...restProps }, ref) => {
    const [uncontrolledExpand, setUncontrolledExpand] = useState(false);
    const open = expand ?? uncontrolledExpand;
    const setOpen = setExpand ?? setUncontrolledExpand;

    const handleExpand = () => {
      setOpen(true);
      const inputEl = ref.current;
      if (inputEl) inputEl.focus();
    };

    const handleClear = () => {
      setOpen(false);
      onClear && onClear();
    }

    return (
      <div
        className={`search-input ${open || keepExpanded ? "expand-search" : ""} ${value ? "has-value" : ""}`}
        onClick={() => {
          !open && !keepExpanded && handleExpand()
        }}
      >
        <input
          ref={ref}
          type="text"
          placeholder="Search"
          defaultValue={value}
          onChange={(e) => onChange && onChange(e)}
          onBlur={e => {
            if (open) {
              setTimeout(() => {
                e.target.value === "" && setOpen(false);
              }, blurDelay);
            }
          }}
          {...restProps}
        />
        {showCloseBtn && <button
          className="icon-close-search"
          onClick={handleClear}
        ></button>}
      </div>
    );
  }
);

export default SearchInput;
