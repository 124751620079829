import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
  useCallback
} from "react";

// contexts
import { useSocket } from "socket";
import { useTabContext } from "components/SuperTabs/TabContext";

// services
import APIService from "services/apiService";
import { alertService } from "services/alertService";
import EventEmitter from "services/eventEmitter";
import { emitter, eventsEnum } from "components/header/AttendanceTabOptions";

// utils
import { convertTimeZone } from "views/Attendance/utils";
import { getUser, isEmpty } from "utils/Common";

// constants
import * as Constants from "components/Constants";
import { AL_BASE_URL } from "../constants"
import { ATTENDANCE_PAGE } from "components/Navigation";

export const unreadEmitter = new EventEmitter();

export const unreadEventsEnum = {
  request_added: 'request_added',
  request_edited: 'request_edited',
  request_deleted: 'request_deleted',
  request_status_change: 'request_status_change',
  comment_added: 'comment_added',
  comment_edited: 'comment_edited',
  comment_deleted: 'comment_deleted',
};

const AttendanceFilterContext = createContext(null);
const getInitialState = () => {
  return {
    date: convertTimeZone(),
    search: "",
    status: "",
    department: [],
    attendance: [],
    isFilterApplied: false,
  };
}

const UPDATE_SEARCH = "UPDATE_SEARCH";
const UPDATE_STATUS = "UPDATE_STATUS";
const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
const RESET_DEPARTMENT = "RESET_DEPARTMENT";
const UPDATE_ATTENDANCE = "UPDATE_ATTENDANCE";
const CLEAR_STATE = "CLEAR_STATE";
const CLEAR_SEARCH = "CLEAR_SEARCH";
const CLEAR_FILTER = "CLEAR_FILTER";

const FILTER_STATE_LOCAL_KEY = Constants.SITE_PREFIX + "attendanceFilterState";

/**
 *
 * @param {typeof INITIAL_FILTER_STATE} state
 * @returns {boolean}
 */
function checkIfFilterApplied(state) {
  const isFilterApplied =
    !isEmpty(state.search) ||
    !isEmpty(state.status) ||
    !isEmpty(state.department) ||
    !isEmpty(state.attendance);

  return isFilterApplied;
}

function filterReducer(state = getInitialState(), action) {
  let newState = JSON.parse(JSON.stringify(state));

  if (action.type === UPDATE_SEARCH) {
    newState.search = action.payload;
  } else if (action.type === UPDATE_STATUS) {
    newState.status = action.payload;
  } else if (action.type === UPDATE_DEPARTMENT) {
    newState.department = action.payload;
  } else if (action.type === RESET_DEPARTMENT) {
    newState.department = action.payload;
  } else if (action.type === UPDATE_ATTENDANCE) {
    newState.attendance = action.payload;
  } else if (action.type === CLEAR_SEARCH) {
    newState.search = "";
  } else if (action.type === CLEAR_FILTER) {
    newState = { ...getInitialState(), search: state.search };
  } else if (action.type === CLEAR_STATE) {
    newState = getInitialState();
  }

  newState.isFilterApplied = checkIfFilterApplied(newState);
  localStorage.setItem(FILTER_STATE_LOCAL_KEY, JSON.stringify(newState));

  return newState;
}

function createInitialState(filterState) {
  const state = localStorage.getItem(FILTER_STATE_LOCAL_KEY);
  if (state) {
    return JSON.parse(state);
  }

  return filterState;
}

export const AttendanceFilterProvider = ({ children }) => {
  // const homePageCountTimeOutIDRef = useRef(null);
  const abortControllerRef = useRef(new AbortController());
  const unReadDataLoadTSRef = useRef();
  const { updateTabProperty } = useTabContext();
  const [state, dispatch] = useReducer(filterReducer, getInitialState(), createInitialState);
  const [date, setDate] = useState(convertTimeZone());
  const [userDetails] = useState(getUser());
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [unReadRequestData, setUnReadRequestData] = useState(null);
  const unReadRequestDataRef = useRef(unReadRequestData);
  const [pinnedUsers, setPinnedUsers] = useState({
    pinned_count: null,
    pinned_resource_ids: '',
  });

  const noOfFiltersApplied = state.department.length + state.attendance.length + state.status.length;

  async function getDepartmentOptions(abortController) {
    try {
      const response = await APIService.apiRequest(
        Constants.API_BASE_URL + "/department",
        null,
        false,
        "GET",
        abortController
      );

      if (response.status === 1) {
        setDepartmentOptions(response.output);
      } else {
        if (!(response instanceof DOMException && response.name === 'AbortError')) {
          alertService.error('Error occured while fetching department options');
        }
      }
    } catch (error) {
      console.log(error);
      alertService.warning(error.message);
    }
  }

  const handleUnreadCountChange = useCallback((unreadData) => {
    setUnReadRequestData(prev => {
      const unreadRequestCopy = structuredClone(prev);
      unreadData.forEach(data => {
        const { request_id, action } = data;
        const [requestType, actionType] = action.split(' ');
        const isComment = requestType === 'comment';
        const isStatusChange = requestType === 'request';
        const status = isStatusChange ? actionType === 'declined' ? 'rejected' : actionType : '';
        const isOptional = data?.optional ?? false;
        let requestStatus = "unknown"; // Default value
        let value = 0;
        const isSelfChange = userDetails.id === data?.created_by;

        value = parseInt(data.request);
        const removeNotification = value < 0;
        const addNotification = !removeNotification;
        requestStatus = status;
        const unreadRequestIds = unreadRequestCopy.unread_request_ids;

        // if there is no optional key, then we are updating the count for all the keys
        // or if optinal key is present we update only if request id is present in the unread_request_ids
        if (!isOptional || (isOptional && unreadRequestIds?.some(id => +id === +request_id))) {
          if (removeNotification || (addNotification && !isSelfChange)) {
            unreadRequestCopy.unread_count = unreadRequestCopy.unread_count + value;
          }
          if (removeNotification) {
            // remove request_id from unread_request_ids
            unreadRequestCopy.unread_request_ids = unreadRequestIds.filter(item => item !== request_id);
          } else if (!isSelfChange) {
            // add request_id to unread_request_ids
            unreadRequestCopy.unread_request_ids = unreadRequestIds.length > 0 ? [...unreadRequestIds, request_id] : [request_id];
          }
        }

        if (!isSelfChange) {
          if (isStatusChange) {
            const shouldAddRequest = value > 0;
            // const event = channel.includes('team') ? 'team_request_status_change' : 'my_request_status_change';
            // const status = channel.split('_')[2];
            const event = 'request_status_change';
            unreadEmitter.emit(unreadEventsEnum[event], { request_id, status, shouldAddRequest });
          } else if (isComment) {
            const event = `comment_${actionType}`;
            // const isTeamRequestEvent = channel.includes('team');
            // !isOptional && unreadEmitter.emit(unreadEventsEnum[event], { request_id, status: requestStatus, data: data.data, isTeamRequestEvent });
            !isOptional && unreadEmitter.emit(unreadEventsEnum[event], { request_id, status: requestStatus, data: data.data });
          } else {
            const event = `request_${actionType}`;
            const shouldEmitEvent = actionType === 'edited' && isOptional ? false : true;
            if (shouldEmitEvent) {
              unreadEmitter.emit(unreadEventsEnum[event], { request_id, status: requestStatus });
            }
          }
        }
      });
      console.log('[UNREAD] output', { unreadData, prev, unreadRequestCopy });
      return { ...unreadRequestCopy };
    });

    // setUnReadRequestData(prev => {
    //   prev = prev || {};
    // })
    // console.log('unreadCount', unreadCount);
    // setUnReadRequestData(unreadCount);
    // emitter.emit(eventsEnum.ON_UPDATE_UNREAD_COUNT, unreadCount);
  }, [userDetails.id]);

  const getUnreadNotificationCount = useCallback(async () => {
    if (abortControllerRef.current.signal.aborted) {
      abortControllerRef.current = new AbortController();
    }
    try {
      const response = await APIService.apiRequest(
        AL_BASE_URL + "/get_homepage_counts",
        null,
        false,
        "GET",
        abortControllerRef.current
      );

      if (response.status === 1) {
        const unreadRequestCount = response?.output?.unread_request_count ?? [];
        const unreadRequest = unreadRequestCount[0] ?? {};
        const unreadCount = unreadRequest.unread_count ?? 0;

        updateTabProperty({
          propertyToUpdate: 'showNotificationDot',
          newValue: unreadCount > 0,
          identifierKey: 'title',
          identifierValue: ATTENDANCE_PAGE.title,
        });

        const output = unreadCount > 0
          ? { ...unreadRequest, unread_request_ids: unreadRequest.unread_request_ids.split(',') }
          : {
            channel_name: 'request',
            unread_count: 0,
            unread_request_ids: [],
            last_unread_timestamp: '',
          };

        setUnReadRequestData(output);
        unReadRequestDataRef.current = output;

        const pinnedData = response?.output?.pinned_count_new?.[0] ?? {
          pinned_count: 0,
          pinned_resource_ids: '',
        };

        setPinnedUsers(pinnedData);
        emitter.emit(eventsEnum.ON_UPDATE_UNREAD_COUNT, unreadCount);

      } else {
        if (!(response instanceof DOMException && response.name === 'AbortError')) {
          alertService.error('Error occured while fetching unread notification count');
        }
      }
    } catch (error) {
      console.log(error);
      alertService.warning(error.message);
    }
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    // getUnreadNotificationCount(abortController);
    getDepartmentOptions(abortController);
    return () => {
      abortController.abort();
      abortControllerRef.current.abort();
    }
  }, []);

  const socket = useSocket();

  useEffect(() => {
    const getUnreadCallback = () => {
      if (unReadDataLoadTSRef.current.getTime() + 5000 < new Date().getTime()) {
        getUnreadNotificationCount();
      }
    }
    socket.on("connect", getUnreadCallback);
    // !unReadRequestDataRef.current && getUnreadNotificationCount();

    socket.on('unread_count', handleUnreadCountChange);
    if (socket.connected || !unReadRequestDataRef.current) {
      getUnreadNotificationCount();
      unReadDataLoadTSRef.current = new Date();
    }

    return () => {
      socket.off("connect", getUnreadCallback);
      socket.off('unread_count', handleUnreadCountChange);
    }
  }, [getUnreadNotificationCount, handleUnreadCountChange]);

  function handleDateChange(date) {
    setDate(date);
  }

  function clear() {
    if (state.isFilterApplied) {
      dispatch({
        type: CLEAR_STATE,
      });
    }
  }

  function updateSearch(searchText) {
    dispatch({
      type: UPDATE_SEARCH,
      payload: searchText,
    });
  }

  function updateStatus(status) {
    dispatch({
      type: UPDATE_STATUS,
      payload: status,
    });
  }

  function updateDepartment(department) {
    dispatch({
      type: UPDATE_DEPARTMENT,
      payload: department,
    });
  }

  function resetDepartment() {
    dispatch({
      type: RESET_DEPARTMENT,
      payload: [],
    });
  }

  function clearSearch() {
    dispatch({
      type: CLEAR_SEARCH,
      payload: [],
    });
  }
  function clearFilter() {
    dispatch({
      type: CLEAR_FILTER,
      payload: [],
    });
  }

  function updateAttendance(attendance) {
    dispatch({
      type: UPDATE_ATTENDANCE,
      payload: attendance,
    });
  }

  function updateUnReadRequestData(unreadRequest) {
    setUnReadRequestData(unreadRequest);
    emitter.emit(eventsEnum.ON_UPDATE_UNREAD_COUNT, unreadRequest);
  }

  function updatePinnedUsers(pinnedUsers) {
    setPinnedUsers(pinnedUsers);
  }

  return (
    <AttendanceFilterContext.Provider
      value={{
        ...state,
        clear,
        updateSearch,
        updateAttendance,
        updateDepartment,
        updateUnReadRequestData,
        updatePinnedUsers,
        resetDepartment,
        updateStatus,
        clearSearch,
        clearFilter,
        date,
        handleDateChange,
        departmentOptions,
        unReadRequestData,
        pinnedUsers,
        noOfFiltersApplied,
      }}
    >
      {children}
    </AttendanceFilterContext.Provider>
  );
};

export const useAttendanceFilters = () => {
  return useContext(AttendanceFilterContext);
};
