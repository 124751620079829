import { useCallback, useEffect, useRef, useState } from 'react';

import { alertService } from 'services/alertService';
import APIService from 'services/apiService';

import Avatar from 'components/Avatar';
import Comments from '../Comments';
import LeaveDetailsCard from './LeaveDetailCard';
import PreviewModal from 'components/PreviewModal/PreviewModal';
import SpecialShiftDetailCard from './SpecialShiftDetailCard';
import StandardShiftDetailCard from '../StandardShiftDetailCard';
import { MouseTooltip } from 'components/ToolTip';

import { useGlobalContext } from 'contexts/GlobalContext';
import useWindowDimensions from 'hooks/useWindowDimensions';

import { AL_BASE_URL, REQUEST_TYPES } from "views/Attendance/constants";
import { unreadEmitter, unreadEventsEnum } from "views/Attendance/context";

import { format, formatTimeAgo } from 'utils/date';
import { convertTimeZone, removeHTMLTags } from 'utils/Common';
import { compareSchedules } from 'views/Attendance/utils';

import './RequestCard.scss';

const RequestCard = ({
    request,
    loggedInUser,
    onRevokeRequest,
    onStatusChange,
    onRequestUpdate,
    isUnread,
    addRequestToUnread,
    markRequestAsRead,
    onEditClick
}) => {
    const { width } = useWindowDimensions();
    const isDesktopVersion = width > 640;

    const { img_url, first_name, middle_name, last_name, manager_img_url, manager_first_name, manager_manager_middle_name, manager_last_name } = request;
    const requestType = request?.request_type;
    const isLeaveRequest = requestType === REQUEST_TYPES.LEAVE;
    const isSpecialRequest = requestType === REQUEST_TYPES.SPECIAL;
    const isStandardRequest = requestType === REQUEST_TYPES.STANDARD;

    const isBereavementLeave = isLeaveRequest && request?.data?.[0]?.leave_type === "Bereavement";
    const isMedicalLeave = isLeaveRequest && request?.data?.[0]?.leave_type === "Medical";
    const isMaternityLeave = isLeaveRequest && request?.data?.[0]?.leave_type === "Maternity";
    const isPaternityLeave = isLeaveRequest && request?.data?.[0]?.leave_type === "Paternity";

    const isWFH = (isSpecialRequest || isStandardRequest) && request?.data?.[0]?.is_wfh === 1;

    const isApproved = request?.is_approved;
    const isRejected = request?.is_rejected;
    const isPending = !isApproved && !isRejected;
    const status = isApproved ? 'approved' : isRejected ? 'declined' : 'pending';

    const isMyRequest = loggedInUser?.resource_id === request.resource_id;

    const requestTimestamps = {
        created: request.created_at,
        approved: request.approval_timestamp,
        declined: request.decline_timestamp,
    }

    const userName = `${first_name} ${middle_name ? `${middle_name} ${last_name}` : last_name}`
    const managerName = `${manager_first_name} ${manager_manager_middle_name ? `${manager_manager_middle_name} ${manager_last_name}` : manager_last_name}`

    const { doesObservesDST, observesDST: doesUserTimeZoneObserveDST, timeZones } = useGlobalContext();
    const timeZone = timeZones.find((tz) => tz.id === request.timezone_id);
    const doesTimeZoneObserveDST = doesObservesDST(timeZone);

    const isSameSchedule = request.request_type === "schedule" ? compareSchedules(request.data, request.dst_data) : false;
    let showDSTData = false;
    if (doesUserTimeZoneObserveDST) {
        showDSTData = !isSameSchedule;
    } else {
        showDSTData = doesTimeZoneObserveDST && !isSameSchedule;
    }

    // const isActivityNotTracked = (isSpecialRequest && request.data?.[0]?.is_activity_tracked === 0) || (isStandardRequest && request.data?.[0]?.is_activity_tracked === 0)

    const requestLabel = isLeaveRequest
        ? `${request.data[0]?.leave_type} Leave`
        : isSpecialRequest ? "Special Shift"
            : isStandardRequest ? "Standard Shift"
                : "";

    const requestCard = {
        [REQUEST_TYPES.LEAVE]: <LeaveDetailsCard data={request.data} />,
        [REQUEST_TYPES.SPECIAL]: <SpecialShiftDetailCard data={request.data} />,
        [REQUEST_TYPES.STANDARD]: <StandardShiftDetailCard data={request.data} dst_data={showDSTData ? request.dst_data : null} />,
    }

    const addCommentRef = useRef();

    const [dataSubmitInProcess, setDataSubmitInProcess] = useState(false);
    const [previewFileData, setPreviewFileData] = useState(null);
    const [showComments, setShowComments] = useState(null);
    const [showFullReason, setShowFullReason] = useState(false);

    const [reason, setReason] = useState('');
    const [statusChangeReasonInput, setStatusChangeReasonInput] = useState(null);

    const updateCommentCount = useCallback((count) => {
        const requestClone = structuredClone(request);
        requestClone.notes_count = count;
        onRequestUpdate(requestClone);
    }, [onRequestUpdate, request]);


    useEffect(() => {
        const unsub_add_comment = unreadEmitter.subscribe(
            unreadEventsEnum.comment_added,
            ({ request_id, status, data, isTeamRequestEvent }) => {
                if (!isTeamRequestEvent && +request_id === +request.resource_request_id) {
                    updateCommentCount(request.notes_count + 1);
                    if (showComments) {
                        const parsedData = JSON.parse(data)[0];
                        addCommentRef.current({ ...parsedData, created_at: convertTimeZone(new Date(parsedData.created_at + ' GMT')) });
                    }
                    addRequestToUnread(request_id);
                }
            }
        );

        // const unsub_edit_comment = unreadEmitter.subscribe(
        //   unreadEventsEnum.comment_edited,
        //   ({ request_id, status }) => {
        //   }
        // );
        // const unsub_delete_comment = unreadEmitter.subscribe(
        //   unreadEventsEnum.comment_deleted,
        //   ({ request_id, status }) => {
        //   }
        // );
        return () => {
            unsub_add_comment();
            // unsub_edit_comment();
            // unsub_delete_comment();
        }
    }, [addRequestToUnread, request.notes_count, request.resource_request_id, updateCommentCount, showComments]);

    const approveRequest = async (e) => {
        e.stopPropagation();
        setDataSubmitInProcess(true);
        try {
            const apiUrl = AL_BASE_URL + `/approve_request/${request.resource_request_id}`;
            const response = await APIService.apiRequest(apiUrl, null, false, "PUT");

            if (response.status === 1) {
                const requestClone = structuredClone(request);
                requestClone.is_approved = 1;
                requestClone.is_rejected = 0;
                requestClone.approval_timestamp = convertTimeZone();
                onStatusChange({ ...requestClone, ...getManagerInfo() });
            } else {
                alertService.error(response.msg);
            }
        } catch (error) {
            alertService.error(error.message);
        } finally {
            setDataSubmitInProcess(false);
        }
    }

    const declineRequest = async () => {
        if (!reason.trim()) return;
        setDataSubmitInProcess(true);
        try {
            const apiPayload = { comment: reason }
            const apiUrl = AL_BASE_URL + `/reject_request/${request.resource_request_id}`;
            const response = await APIService.apiRequest(apiUrl, apiPayload, false, "PUT");

            if (response.status === 1) {
                const requestClone = { ...request };
                requestClone.is_approved = 0;
                requestClone.is_rejected = 1;
                requestClone.decline_timestamp = convertTimeZone();
                requestClone.decline_reason = reason;
                onStatusChange({ ...requestClone, ...getManagerInfo() });
                closeReasonInput();
            } else {
                alertService.error(response.msg);
            }
        } catch (error) {
            alertService.error(error.message);
        } finally {
            setDataSubmitInProcess(false);
        }
    }

    const handleInputChange = (e) => {
        setReason(e.target.value);
    }

    const toggleComments = () => {
        setShowComments(!showComments);
    }

    const openDeclineReasonInput = (e) => {
        e.stopPropagation();
        setStatusChangeReasonInput('decline');
        if (showComments) setShowComments(false);
    }

    const closeReasonInput = (e) => {
        e?.stopPropagation?.();
        setStatusChangeReasonInput(null);
        setReason('');
    }

    const handleSubmit = (e) => {
        e.stopPropagation()
        declineRequest();
    }

    const getManagerInfo = () => {
        return {
            manager_first_name: loggedInUser.first_name,
            manager_last_name: loggedInUser.last_name,
            manager_middle_name: loggedInUser.middle_name,
            manager_img_url: loggedInUser.img_url,
        }
    }

    const hasFooter = statusChangeReasonInput || showComments || (!isPending && !showComments && !statusChangeReasonInput)

    const isOverdue = isLeaveRequest && request.data.some((day) => day.is_overdue === 1);
    const isShortNotice = isLeaveRequest && request.data.some((day) => day.is_short_notice === 1);

    return (
        <div
            className={`request-card-wrapper ${!hasFooter ? 'no-footer' : ''} ${!isPending ? 'fade-out' : ''}`}
            id={`request_${request.resource_request_id}`}
            onClick={() => {
                if (isPending) {
                    setShowFullReason(!showFullReason);
                }
            }}
        >
            <div className='avatar-wrapper'>
                <Avatar
                    imgSrc={img_url}
                    firstName={first_name}
                    lastName={last_name}
                    height={isDesktopVersion ? 30 : 36}
                    width={isDesktopVersion ? 30 : 36}
                    fontSize={12}
                />
            </div>
            <div className='request-header'>
                <div className="font-bold author-name">{userName}</div>
                <MouseTooltip content={format(
                    requestTimestamps.created,
                    "ddd, MMM DD, YYYY, hh:mm A"
                )}>
                    <div className='request-card-label request-date'>{formatTimeAgo(requestTimestamps.created)}</div>
                </MouseTooltip>
                <div className='request-sub-header'>
                    <button className='icon icon-info'></button>
                    <div className='request-info'>
                        <div className='request-label'>{requestLabel}</div>
                        {isSpecialRequest || isStandardRequest ? <div className='location-type-wrapper'>
                            <span className={`location-type ${isWFH ? 'remote' : 'office'}`}></span>
                            <span>{isWFH ? 'Remote' : 'Office'}</span>
                        </div> : null}
                        {isSpecialRequest || isStandardRequest ? <div className='location-type-wrapper'>
                            <span className={`work-type ${request.data[0]?.is_activity_tracked === 1 ? 'online' : 'offline'}`}></span>
                            <span>{request.data[0]?.is_activity_tracked === 1 ? 'Online' : 'Offline'}</span>
                        </div> : null}
                        {isLeaveRequest && !(isBereavementLeave || isMedicalLeave || isMaternityLeave || isPaternityLeave) && (isOverdue || isShortNotice) && (
                            <div className='request-card-label'>
                                {isOverdue ? 'Overdue' : 'Short-notice'}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={`action-btn-wrapper ${showComments || isUnread ? 'show' : ''}`}>
                {!statusChangeReasonInput && <MouseTooltip
                    show={isDesktopVersion}
                    content='Comments'
                    style={{ lineHeight: "14px" }}
                >
                    <button onClick={toggleComments} className={`comment-icon-wrapper ${showComments ? 'active' : ''}`}>
                        <div className={`comment-icon-wrapper-inner ${isUnread ? 'notification-dot' : ''}`}>
                            <div className='icon icon-comment'></div>
                        </div>
                    </button>
                </MouseTooltip>}
                {(isMyRequest ? !isApproved : true) && <div className={`action-buttons-inner ${isUnread ? 'hide' : ''}`}>
                    <button className='icon icon-more'></button>
                    {(isDesktopVersion || (!isDesktopVersion && !statusChangeReasonInput)) && <div className='action-buttons'>
                        {isMyRequest ? <>
                            {!isApproved && (
                                <MouseTooltip
                                    content='Edit'
                                    style={{ lineHeight: "14px" }}
                                >
                                    <button
                                        className="icon-status"
                                        disabled={dataSubmitInProcess}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            onEditClick(request)
                                        }}
                                    >
                                        {!isDesktopVersion ? 'Edit' : <span className="icon icon-edit"></span>}
                                    </button>
                                </MouseTooltip>

                            )}
                            {isPending && (
                                <MouseTooltip
                                    content='Revoke'
                                    style={{ lineHeight: "14px" }}
                                >
                                    <button
                                        className="icon-status"
                                        disabled={dataSubmitInProcess}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            onRevokeRequest(request)
                                        }}
                                    >
                                        {!isDesktopVersion ? 'Revoke' : <span className="icon icon-revoke"></span>}
                                    </button>
                                </MouseTooltip>
                            )}
                        </> : <>
                            {!statusChangeReasonInput && <>
                                {!isApproved && (
                                    <MouseTooltip
                                        content='Approve'
                                        style={{ lineHeight: "14px" }}
                                    >
                                        <button
                                            className="icon-status"
                                            disabled={dataSubmitInProcess}
                                            onClick={approveRequest}
                                        >
                                            {!isDesktopVersion ? 'Approve' : <span className="icon icon-approve"></span>}
                                        </button>
                                    </MouseTooltip>
                                )}
                                {!isRejected && (
                                    <MouseTooltip
                                        content='Decline'
                                        style={{ lineHeight: "14px" }}
                                    >
                                        <button
                                            className="icon-status"
                                            disabled={dataSubmitInProcess}
                                            onClick={openDeclineReasonInput}
                                        >
                                            {!isDesktopVersion ? 'Decline' : <span className="icon icon-decline"></span>}
                                        </button>
                                    </MouseTooltip>
                                )}
                            </>}
                        </>}
                    </div>}
                </div>}
            </div>
            <div className='request-body'>
                {(isPending || showComments) && (
                    <div className={`request-reason ${showComments || showFullReason ? 'show-more' : ''}`}>
                        {removeHTMLTags(request.reason)}
                    </div>
                )}
                {requestCard[request.request_type]}
                {isMedicalLeave && request?.request_attachemnt_url?.length > 0 && (
                    <div className="medical-attachments">
                        <div className="attachments">
                            {request.request_attachemnt_url.split(',').map((url) => {
                                const name = url.split('_T_L_').pop();
                                return (
                                    <button
                                        key={url}
                                        className="attachment"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setPreviewFileData({ url, name })
                                        }}
                                        title={name}
                                    >
                                        {name}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                )}
                {previewFileData &&
                    <PreviewModal
                        isOpen={true}
                        url={previewFileData.url}
                        title={previewFileData.name}
                        toggle={(e) => setPreviewFileData(null)} />
                }
            </div>

            {hasFooter && <div className='request-footer'>
                {!isPending && !showComments && !statusChangeReasonInput && <div className='comment-wrapper status-comment-wrapper'>
                    <div className='status-avatar-wrapper'>
                        <Avatar
                            imgSrc={manager_img_url}
                            firstName={manager_first_name}
                            lastName={manager_last_name}
                            height={isDesktopVersion ? 30 : 36}
                            width={isDesktopVersion ? 30 : 36}
                            fontSize={12}
                        />
                    </div>
                    <div className="font-bold author-name">{managerName}</div>
                    <div className='request-card-label status-request-date'>
                        {requestTimestamps[status] ? (
                            <>
                                <MouseTooltip
                                    content={format(
                                        requestTimestamps[status],
                                        "ddd, MMM DD, YYYY, hh:mm A"
                                    )}
                                    style={{ lineHeight: "14px" }}
                                >
                                    {formatTimeAgo(requestTimestamps[status], new Date(convertTimeZone()))}
                                </MouseTooltip>
                                {!isDesktopVersion && <span>{format(requestTimestamps[status], ' • MMM DD')}</span>}
                            </>
                        ) : (
                            <span>unknown</span>
                        )}
                    </div>
                    <div className='request-card-label capitalize request-status'>
                        <span className={`icon-${status}`}>{status}</span>
                        {isRejected ? <span className='status-comment'>{removeHTMLTags(request.decline_reason)}</span> : null}
                    </div>
                </div>}

                {showComments && (
                    <Comments
                        resource_request_id={request.resource_request_id}
                        updateCommentCount={updateCommentCount}
                        markRequestAsRead={markRequestAsRead}
                        addCommentRef={addCommentRef}
                        allowEditing={isPending}
                    />
                )}

                {statusChangeReasonInput && <div className='reason-input-wrapper'>
                    <div className='reason-input-wrapper-inner'>
                        <div className='help-text'>
                            You are about to decline {!isApproved ? 'this request' : 'this approved request'}. Please provide a reason before submitting.
                        </div>
                        <textarea value={reason} onChange={handleInputChange} placeholder='Message' />
                    </div>
                    <div className='input-btn-wrapper'>
                        <button className='btn-block' disabled={dataSubmitInProcess} onClick={handleSubmit}>Submit</button>
                        <button className='btn-block' disabled={dataSubmitInProcess} onClick={closeReasonInput}>Cancel</button>
                    </div>
                </div>}
            </div>}
        </div>
    )
}

export default RequestCard