import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from "react-router-dom";
import SuperPager from 'super-pager';
// components
import Panel from 'components/Panel';
import LeaveRequestForm from './LeaveRequestForm';
import ShiftRequestForm from './ShiftRequestForm';
import LineLoader from 'components/LineLoader';
import RequestCard from './RequestCard';
import Filter from './Filter';
import AlertPopup from 'components/AlertPopup/AlertPopup';
import ClickOutsideListner from 'components/ClickOutsideListner';
// contexts
import { useGlobalContext } from 'contexts/GlobalContext';
import { useTabContext } from 'components/SuperTabs/TabContext';
import { useAttendanceFilters } from 'views/Attendance/context';
// hooks
import useWindowDimensions from 'hooks/useWindowDimensions';
import useUserData from 'hooks/useUserData';
import { useOnClickOutside } from 'hooks/useClickOutside';
// services
import APIService from 'services/apiService';
import { alertService } from 'services/alertService';
// utils
import { convertTimeZone } from 'utils/Common';
import { addDays, format, subDays } from 'utils/date';
import { AL_BASE_URL, REQUEST_TYPES, INITIAL_PAGINATION_META, SHIFT_TYPE_OPTIONS } from "../../constants";
import { requestStatusOptions, requestOptions } from './constants';
import { convertHhmmToUserTimezone, getDateFromHhmm, getMyTimezoneOffset } from "utils/Common";
import { unreadEmitter, unreadEventsEnum } from "views/Attendance/context";
import { ATTENDANCE_PAGE } from 'components/Navigation';
// styles
import './RequestsModal.scss';

const getDefaultFilterState = (addLast7Days = true) => {
    const last7Days = `${format(subDays(new Date(), 7), 'YYYY-MM-DD')}_${format(new Date(), 'YYYY-MM-DD')}`
    return {
        searchString: "",
        selectedRequestStatus: [], // e.g. approved, declined
        selectedRequests: [], // e.g. my_requests, team_requests
        selectedLeaveType: [], // e.g. medical, casual
        selectedShiftType: [], // e.g. special, standard
        dateCreatedAt: "", // e.g. 2023-01-01_2023-01-01 or 2023-01-01_2023-01-31
        requestedAt: "", // e.g. 2023-01-01_2023-01-01 or 2023-01-01_2023-01-31
        updatedAt: addLast7Days ? last7Days : "", // e.g. 2023-01-01_2023-01-01 or 2023-01-01_2023-01-31
    }
}

const PAGE_SIZE = 10;

function getFormatTimeStr(time) {
    const hours = time.slice(0, 2);
    const minutes = time.slice(2);
    return `${hours}:${minutes}`;
}

function incrementEndDate(dateRange) {
    const [startDate, endDate] = dateRange.split('_');
    const newEndDate = format(addDays(endDate, 1), 'YYYY-MM-DD');
    return `${startDate}_${newEndDate}`;
}

const updateMetaOnRemove = (prev) => {
    const newCount = prev.total_results - 1;
    return {
        ...prev,
        total_results: newCount,
        num_pages: Math.ceil(newCount / PAGE_SIZE),
    };
};

const updateMetaOnAdd = (prev) => {
    const newCount = prev.total_results + 1;
    return {
        ...prev,
        total_results: newCount,
        num_pages: Math.ceil(newCount / PAGE_SIZE),
    };
};

const getScheduleData = (data) => {
    return data.map((s) => {
        const { is_activity_tracked = 1, is_wfh = 1, start_time, end_time, weekday } = s;
        return {
            is_activity_tracked,
            is_wfh,
            start_time: getFormatTimeStr(start_time.time),
            end_time: getFormatTimeStr(end_time.time),
            weekday,
        }
    })
}

const RequestsModal = forwardRef(({ toggle, leaveOptions }, ref) => {
    const { isManager, user } = useUserData();
    const { width } = useWindowDimensions();
    const isDesktopVersion = width > 640;

    const loggedInUser = { ...user.resource, resource_id: user.resource.id };
    const history = useHistory();
    const { isSelectedOrgEmployee, myTimeZone } = useGlobalContext();
    const { updateTabProperty } = useTabContext();
    const { unReadRequestData, updateUnReadRequestData } = useAttendanceFilters();

    const abortControllerForReadAPIRef = useRef(new AbortController());
    const unReadRequestDataRef = useRef(unReadRequestData);
    const fakeLoaderRef = useRef(null);

    const resetAbortControllerForReadAPI = () => {
        abortControllerForReadAPIRef.current.abort();
        abortControllerForReadAPIRef.current = new AbortController();
    }

    useEffect(() => {
        unReadRequestDataRef.current = unReadRequestData
    }, [unReadRequestData]);

    const abortControllerRef = useRef(new AbortController());
    const requestsMetaRef = useRef(INITIAL_PAGINATION_META);

    const [showRequestTypeMenu, setShowRequestTypeMenu] = useState(false)
    const [showRequestForm, setShowRequestForm] = useState(null)
    const [isShiftDataLoading, setIsShiftDataLoading] = useState(false);
    const [currentSchedule, setCurrentSchedule] = useState(null);
    const [showLineLoader, setShowLineLoader] = useState(false);
    const [requests, setRequests] = useState([]);
    const [revokeRequestInfo, setRevokeRequestInfo] = useState(null);
    const [requestsMeta, setRequestsMeta] = useState(INITIAL_PAGINATION_META);
    const [unreadRequestIds, setUnreadRequestIds] = useState(structuredClone(unReadRequestData?.unread_request_ids || []));

    const [selectedFormType, setSelectedFormType] = useState(null);
    const [selectedLeaveType, setSelectedLeaveType] = useState(leaveOptions[1]);
    const [selectedShiftType, setSelectedShiftType] = useState(SHIFT_TYPE_OPTIONS[0]);
    
    const [isRequestFormInEditMode, setIsRequestFormInEditMode] = useState(false);
    const [editData, setEditData] = useState(null);

    const [filterState, setFilterState] = useState(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const request_id = searchParams.get("request_id");
        const filters = getDefaultFilterState(!request_id);
        if (request_id) {
            filters.searchString = `request_id:${request_id}`
        }
        return filters;
    });

    const resetAbortController = () => {
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();
    }

    const [showDiscardChangesAlert, setShowDiscardChangesAlert] = useState(false);
    const isFormChangesSavedFnRef = useRef(null);

    useOnClickOutside(ref, () => {
        if (typeof isFormChangesSavedFnRef.current === 'function') {
            if (isFormChangesSavedFnRef.current() === false) {
                setShowDiscardChangesAlert(true);
                return;
            }
        }
        toggle();
    });

    const toggleRequestTypeMenu = () => {
        setShowRequestTypeMenu(!showRequestTypeMenu)
    }

    const closeRequestForm = () => {
        setSelectedFormType(null);
        setIsRequestFormInEditMode(false);
        setEditData(null);
    }

    const checkRequestMatchesFilter = useCallback(({ request } = {}) => {
        // check if the request matches the filter criteria
        const { searchString, requestedAt, dateCreatedAt, updatedAt, selectedRequestStatus, selectedLeaveType, selectedRequests, selectedShiftType } = filterState;
        const { data, is_approved, is_rejected, resource_id, request_type } = request;
        const isMyRequest = resource_id === loggedInUser.resource_id;
        // const isLeaveRequest = request.request_type === REQUEST_TYPES.LEAVE;
        // const isSpecialShiftRequest = request.request_type === REQUEST_TYPES.SPECIAL;
        const isStandardShiftRequest = request.request_type === REQUEST_TYPES.STANDARD;

        let isReadStatusMatching = true,
            isRequestStatusMatching = true,
            isRequestTypeMatching = true,
            isSelectedRequestsMatching = true,
            isSearchMatching = true,
            isDateCreatedAtMatching = true,
            isRequestedAtMatching = true,
            isUpdatedAtMatching = true;

        if (selectedRequestStatus.length > 0) {
            if (!selectedRequestStatus.some((status) => status.is_approved === is_approved && status.is_rejected === is_rejected)) {
                isRequestStatusMatching = false;
            }
        }

        if (selectedLeaveType.length > 0 || selectedShiftType.length > 0) {
            if (request_type === REQUEST_TYPES.LEAVE) {
                if (!selectedLeaveType.some((leaveType) => leaveType.name === data[0].leave_type)) {
                    isRequestTypeMatching = false;
                }
            } else {
                if (!selectedShiftType.some((shiftType) => shiftType.value === request_type)) {
                    isRequestTypeMatching = false;
                }
            }
        }

        if (selectedRequests.length > 0) {
            if (!selectedRequests.some(req => (req.name === (isMyRequest ? "My Requests" : "Team Requests")))) {
                isSelectedRequestsMatching = false;
            }
        }

        if (searchString) {
            const searchStr = searchString.toLowerCase();
            const string = request.resource_request_id + `${request.first_name}${request.middle_name}${request.last_name}`.replace(/\s+/g, "").toLowerCase();
            if (!string.includes(searchStr)) {
                isSearchMatching = false;
            }
        }

        if (dateCreatedAt) {
            const [startDate, endDate] = dateCreatedAt.split('_');
            const createdDate = format(request.created_at, 'YYYY-MM-DD');
            if (!(startDate <= createdDate && endDate >= createdDate)) {
                isDateCreatedAtMatching = false;
            }
        }

        if (updatedAt) {
            const [startDate, endDate] = updatedAt.split('_');
            const updatedDate = format(request.updated_at, 'YYYY-MM-DD');
            if (!(startDate <= updatedDate && endDate >= updatedDate)) {
                isUpdatedAtMatching = false;
            }
        }

        if (requestedAt) {
            const [startDate, endDate] = requestedAt.split('_');
            if (isStandardShiftRequest) {
                const effectiveDate = format(request.data[0].effective_start_date, 'YYYY-MM-DD');
                if (!(startDate <= effectiveDate && endDate >= effectiveDate)) {
                    isDateCreatedAtMatching = false;
                }
            } else {
                isDateCreatedAtMatching = request.data?.some(slot => slot.start_date <= endDate && slot.end_date >= startDate);
            }
        }

        return isRequestStatusMatching && isRequestTypeMatching && isSelectedRequestsMatching && isSearchMatching && isDateCreatedAtMatching && isUpdatedAtMatching && isRequestedAtMatching && isReadStatusMatching;

    }, [filterState, loggedInUser.resource_id]);

    const onRequestSubmit = (request) => {
        let matchesFilter = checkRequestMatchesFilter({ request });

        if (matchesFilter) {
            setRequests((prev) => {
                // TODO: need to sort data
                const newRequests = [request, ...prev];
                if (requestsMeta.total_results !== prev.length) {
                    newRequests.pop();
                }
                return newRequests;
            });

            const updatedMeta = updateMetaOnAdd(requestsMetaRef.current);
            setRequestsMeta(updatedMeta);
            requestsMetaRef.current = updatedMeta;
        }
    };

    const onRequestReSubmit = (request) => {
        setRequests((prev) => {
            return prev.map(req => {
                if (req.resource_request_id === request.resource_request_id) {
                    // accurate notes_count is not available in the response, so we are using the previous value
                    return { ...req, ...request, notes_count: req.notes_count };
                }

                return req;
            })
        });
        // We are not allowed to edit approved requests
    }

    const requestForm = (type) => {
        if (type === REQUEST_TYPES.SPECIAL || type === REQUEST_TYPES.STANDARD) {
            return <ShiftRequestForm
                isEditMode={isRequestFormInEditMode}
                data={editData}
                leaveOptions={leaveOptions}
                shiftOptions={SHIFT_TYPE_OPTIONS}
                isFormChangesSavedFnRef={isFormChangesSavedFnRef}
                currentSchedule={currentSchedule}
                selectedLeaveType={selectedLeaveType}
                selectedShiftType={selectedShiftType}
                setSelectedLeaveType={setSelectedLeaveType}
                setSelectedShiftType={setSelectedShiftType}
                selectedFormType={selectedFormType}
                setSelectedFormType={setSelectedFormType}
                loggedInUser={loggedInUser}
                onClose={closeRequestForm}
                onSubmit={onRequestSubmit}
                onResubmit={onRequestReSubmit}
            />
        } else {
            return <LeaveRequestForm
                isEditMode={isRequestFormInEditMode}
                data={editData}
                leaveOptions={leaveOptions}
                shiftOptions={SHIFT_TYPE_OPTIONS}
                isFormChangesSavedFnRef={isFormChangesSavedFnRef}
                selectedLeaveType={selectedLeaveType}
                selectedShiftType={selectedShiftType}
                setSelectedLeaveType={setSelectedLeaveType}
                setSelectedShiftType={setSelectedShiftType}
                selectedFormType={selectedFormType}
                setSelectedFormType={setSelectedFormType}
                loggedInUser={loggedInUser}
                onClose={closeRequestForm}
                onSubmit={onRequestSubmit}
                onResubmit={onRequestReSubmit}
            />
        }
    }

    const onFilterChange = (filterState) => {
        resetAbortController();
        setFilterState(() => filterState);

        let request_id = null;
        if (filterState?.search?.startsWith('request_id:')) {
            request_id = filterState.search.split(':')[1];
        }

        fetchRequests({
            ...filterState,
            controller: abortControllerRef.current,
            request_id,
        });
    }

    const fakeLoader = () => {
        setShowLineLoader(true);
        fakeLoaderRef.current = setTimeout(() => {
            setShowLineLoader(false);
        }, 800);
    }

    const clearFilter = () => {
        onFilterChange({ ...getDefaultFilterState(false), searchString: filterState.searchString });
    }

    const addRequestToUnread = (request_id) => {
        const isRequestUnread = unreadRequestIds?.some(req => +req.resource_request_id === +request_id);
        if (!isRequestUnread) {
            setUnreadRequestIds(prev => [...prev, request_id]);
        }
        readChannel();
    }

    const markRequestAsRead = (request_id) => {
        const isRequestUnread = unreadRequestIds?.some(req_id => +req_id === +request_id);
        if (isRequestUnread) {
            setUnreadRequestIds(prev => prev?.filter(req_id => +req_id !== +request_id));
        }
    }

    const revokeRequest = async (request) => {
        setRevokeRequestInfo(null);
        try {
            const { resource_request_id: requestId, request_type: requestType } = request
            const response = await APIService.apiRequest(
                AL_BASE_URL + `/request/${requestType}/${requestId}`,
                null,
                false,
                "DELETE"
            );
            if (response.status === 1) {
                setRequests(prev => prev.filter(req => req.resource_request_id !== +requestId));
                const updatedMeta = updateMetaOnRemove(requestsMetaRef.current);
                setRequestsMeta(updatedMeta);
                requestsMetaRef.current = updatedMeta;
                if (requests.length !== requestsMeta.total_results) {
                    fetchRequests({ page_number: requests.length, page_size: 1, flush: false, updateMeta: false, ...filterState, });
                }
            } else {
                alertService.error(response.msg);
            }
        } catch (error) {
            alertService.error(error.message);
        }
    }

    function handleRequestEditClick(request) {
        if (request.request_type === REQUEST_TYPES.LEAVE) {
            setSelectedFormType(REQUEST_TYPES.LEAVE);
            setSelectedLeaveType(
                leaveOptions.find(
                    (option) => option.name === request.data[0].leave_type
                )
            );

            setEditData({
                leave_days: request.data.map((d) => ({
                    start_date: d.start_date,
                    end_date: d.end_date,
                    leave_type: d.leave_type,
                })),
                comment: request.reason,
                reason_comment_id: request.reason_comment_id,
                id: request.resource_request_id,
                created_at: request.created_at,
                request_attachment_url: request?.request_attachemnt_url,
            });
            setIsRequestFormInEditMode(true);
            setShowRequestForm(REQUEST_TYPES.LEAVE);
        } else {
            const shiftType = SHIFT_TYPE_OPTIONS.find((option) => option.id === request.request_type)
            setSelectedFormType(shiftType.id);
            setSelectedShiftType(shiftType);
            let data = {
                comment: request.reason,
                reason_comment_id: request.reason_comment_id,
                id: request.resource_request_id,
                type: request.request_type,
                created_at: request.created_at,
                is_activity_tracked: request.data[0].is_activity_tracked,
                is_wfh: request.data?.[0]?.is_wfh ?? 1,
            };

            if (request.request_type === REQUEST_TYPES.SPECIAL) {
                data.specialShifts = request.data.reduce((acc, cur) => {
                    const shiftIndex = acc.findIndex(
                        (shift) =>
                            shift.start_date === cur.start_date &&
                            shift.end_date === cur.end_date
                    );
                    const { is_activity_tracked = 1, is_full_day, is_wfh = 1, start_date, end_date, start_time, end_time } = cur;

                    if (shiftIndex === -1) {
                        acc.push({
                            start_date: start_date,
                            end_date: end_date,
                            is_activity_tracked,
                            is_full_day,
                            is_wfh,
                            timings: [
                                {
                                    start_time: getFormatTimeStr(start_time.time),
                                    end_time: getFormatTimeStr(end_time.time),
                                },
                            ],
                        });
                    } else {
                        acc[shiftIndex].timings.push({
                            start_time: getFormatTimeStr(start_time.time),
                            end_time: getFormatTimeStr(end_time.time),
                        });
                    }

                    return acc;
                }, []);
            } else {
                data.standardShift = {
                    start_date: request.data[0]?.effective_start_date,
                    schedule: getScheduleData(request.data),
                    dst_schedule: getScheduleData(request.dst_data),
                };
            }

            // data.is_activity_tracked = request.data?.[0]?.is_activity_tracked;

            setEditData(data);
            setIsRequestFormInEditMode(true);
            setShowRequestForm(REQUEST_TYPES.SPECIAL);
        }
    }

    const handleRequestUpdate = (request) => {
        setRequests((prev) => {
            return prev.map(req => {
                if (req.resource_request_id === request.resource_request_id) {
                    return request
                }

                return req;
            })
        });
    }

    const handleStatusChange = (request) => {
        setRequests((prev) => {
            return prev.map(req => {
                if (req.resource_request_id === request.resource_request_id) {
                    return request
                }

                return req;
            })
        });
    }

    const handleRevokeRequest = (request) => {
        setRevokeRequestInfo(request);
    }

    const getRequestsContent = () => {
        if (requests.length === 0) {
            if (!showLineLoader) {
                return (
                    <span
                        style={{
                            textAlign: "center",
                            width: "100%",
                            display: "block",
                        }}
                    >
                        No data to display
                    </span>
                );
            }
            return null;
        }

        const requestsWithMonth = requests.map(request => ({
            ...request,
            month: format(request.updated_at, "MMM_YYYY"),
        }));

        const groupedRequests = Object.entries(
            requestsWithMonth.reduce((acc, request) => {
                acc[request.month] = [...(acc[request.month] || []), request];
                return acc;
            }, {})
        );

        return groupedRequests.map(([month, requests]) => (
            <div className='month-section' key={month}>
                <div className="month-pill">
                    <div className="month-pill-text">{month.replace("_", " ")}</div>
                </div>
                {requests.map(request => (
                    <RequestCard
                        isUnread={unreadRequestIds?.some(id => +id === +request.resource_request_id)}
                        key={request.resource_request_id}
                        loggedInUser={loggedInUser}
                        request={request}
                        onEditClick={handleRequestEditClick}
                        onRequestUpdate={handleRequestUpdate}
                        onRevokeRequest={handleRevokeRequest}
                        addRequestToUnread={addRequestToUnread}
                        onStatusChange={handleStatusChange}
                        markRequestAsRead={markRequestAsRead}
                    />
                ))}
            </div>
        ));
    }

    const readChannel = useCallback(async () => {
        try {
            const response = await APIService.apiRequest(
                `${AL_BASE_URL}/read_request/request`,
                null,
                false,
                "PUT",
                abortControllerForReadAPIRef.current
            );
            if (response.status === 1) {
                updateUnReadRequestData({
                    unread_count: 0,
                    unread_request_ids: [],
                    last_unread_timestamp: convertTimeZone(),
                });
                updateTabProperty({
                    propertyToUpdate: 'showNotificationDot',
                    newValue: false,
                    identifierKey: 'title',
                    identifierValue: ATTENDANCE_PAGE.title,
                });
            }
        } catch (error) {
            console.log("[readChannel] catch error", error);
        }
    }, [updateTabProperty, updateUnReadRequestData]);

    const fetchRequests = useCallback(async ({
        flush = true,
        page_number = 1,
        page_size = PAGE_SIZE,
        controller = abortControllerRef.current,
        request_id = null,
        searchString = "",
        selectedRequestStatus = [],
        selectedLeaveType = [],
        selectedShiftType = [],
        selectedRequests = [],
        dateCreatedAt = "",
        requestedAt = "",
        updatedAt = "",
        updateMeta = true,
        updateExistingRequest = false,
        prepend = false,
        call_unread_API = false,
    }) => {
        try {
            setShowLineLoader(true);

            const url = new URL(AL_BASE_URL + "/request");
            if (!isManager || (selectedRequests.length > 0 && selectedRequests.length !== requestOptions.length)) {
                url.searchParams.append("resource_id", selectedRequests.some((r) => r.name !== "My Requests") ? "team" : loggedInUser.resource_id);
            }
            if (selectedRequestStatus.length > 0 && selectedRequestStatus.length !== requestStatusOptions.length) {
                url.searchParams.append("status", selectedRequestStatus.map((s) => s.value));
            }
            url.searchParams.append("page_number", request_id ? 1 : page_number);
            url.searchParams.append("page_size", request_id ? 1 : page_size);

            if (selectedLeaveType.length > 0 && leaveOptions.length !== selectedLeaveType.length) {
                url.searchParams.append("leave_type_id", selectedLeaveType.map(leaveType => leaveType.id));
            }
            if (selectedShiftType.length > 0 || selectedLeaveType.length > 0) {
                let val = [];
                if (selectedShiftType.length > 0) val = selectedShiftType.map(shiftType => shiftType.value);
                if (selectedLeaveType.length > 0) val.push("leave");
                url.searchParams.append("request_type", val);
            }

            if (searchString.includes("request_id:") || request_id) {
                url.searchParams.append("request_id", searchString.split(":")[1] || request_id);
            } else if (searchString) {
                url.searchParams.append("search_string", searchString);
            }

            if (dateCreatedAt) {
                url.searchParams.append("date_created_at", incrementEndDate(dateCreatedAt));
            }
            if (requestedAt) {
                url.searchParams.append("request_date", requestedAt);
            }
            if (updatedAt) {
                url.searchParams.append("date_updated_at", incrementEndDate(updatedAt));
            }

            resetAbortControllerForReadAPI();

            const response = await APIService.apiRequest(
                url.toString(),
                null,
                false,
                "GET",
                controller
            );

            if (response.status === 1) {
                if (call_unread_API || (!request_id && unReadRequestData?.unread_count > 0)) {
                    readChannel();
                }

                const convertSpecialShiftRequestTimezone = (req) => {
                    return {
                        ...req,
                        data: req.data.map(shift => {
                            const tzOffset = getMyTimezoneOffset(getDateFromHhmm(shift.start_time.time, shift.start_date))
                            const [start_time, start_time_offset] = convertHhmmToUserTimezone(shift.start_time.time, tzOffset);
                            const [end_time, end_time_offset] = convertHhmmToUserTimezone(shift.end_time.time, tzOffset);
                            return {
                                ...shift,
                                start_time: {
                                    time: start_time,
                                    time_offset: start_time_offset,
                                },
                                end_time: {
                                    time: end_time,
                                    time_offset: end_time_offset,
                                }
                            }
                        })
                    }
                }

                const convertStandardShiftRequestTimezone = (req) => {
                    const tzOffset = myTimeZone.utc_offset;
                    const dstTZOffset = myTimeZone.dst_utc_offset ?? tzOffset;
                    return {
                        ...req,
                        data: req.data.map(shift => {
                            const [start_time, start_time_offset] = convertHhmmToUserTimezone(shift.start_time.time, tzOffset);
                            const [end_time, end_time_offset] = convertHhmmToUserTimezone(shift.end_time.time, tzOffset);
                            return {
                                ...shift,
                                start_time: {
                                    time: start_time,
                                    time_offset: start_time_offset,
                                },
                                end_time: {
                                    time: end_time,
                                    time_offset: end_time_offset,
                                }
                            }
                        }),
                        dst_data: req?.dst_data?.map(shift => {
                            const [start_time, start_time_offset] = convertHhmmToUserTimezone(shift.start_time.time, dstTZOffset);
                            const [end_time, end_time_offset] = convertHhmmToUserTimezone(shift.end_time.time, dstTZOffset);
                            return {
                                ...shift,
                                start_time: {
                                    time: start_time,
                                    time_offset: start_time_offset,
                                },
                                end_time: {
                                    time: end_time,
                                    time_offset: end_time_offset,
                                }
                            }
                        }),
                    }
                }

                const convertRequestsTimezone = (requests) => {
                    return requests.map(req => {
                        req.created_at = req.created_at ? convertTimeZone(new Date(req.created_at)) : null;
                        req.updated_at = req.updated_at ? convertTimeZone(new Date(req.updated_at)) : null;
                        req.approval_timestamp = req.approval_timestamp ? convertTimeZone(new Date(req.approval_timestamp)) : null;
                        req.pending_timestamp = req.pending_timestamp ? convertTimeZone(new Date(req.pending_timestamp)) : null;
                        req.decline_timestamp = req.decline_timestamp ? convertTimeZone(new Date(req.decline_timestamp)) : null;

                        if (req.request_type === REQUEST_TYPES.SPECIAL) {
                            return convertSpecialShiftRequestTimezone(req);
                        }

                        if (req.request_type === REQUEST_TYPES.STANDARD) {
                            return convertStandardShiftRequestTimezone(req);
                        }

                        // no need to convert time for leave request
                        return req;
                    });
                }

                const requests = convertRequestsTimezone(response.output);
                const paginationMeta = response.meta;

                if (updateMeta) {
                    setRequestsMeta(() => paginationMeta);
                    requestsMetaRef.current = paginationMeta;
                }
                setRequests((oldRequests) => {
                    let data = structuredClone(requests);
                    if (updateExistingRequest) {
                        return oldRequests.map(req => {
                            if (+req.resource_request_id === +request_id) {
                                return requests[0];
                            }
                            return req;
                        });
                    }
                    if (!flush) {
                        data = prepend ? [...requests, ...oldRequests] : [...oldRequests, ...requests];
                    }

                    if (prepend && requestsMetaRef.current.total_results !== requests.length) {
                        if (request_id ? requests.length > 0 : true) {
                            data.pop();
                        }
                    }
                    return data;
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            if (!controller?.signal?.aborted) {
                setShowLineLoader(false);
            }
        }
    }, [isManager, leaveOptions.length, loggedInUser.resource_id, myTimeZone.dst_utc_offset, myTimeZone.utc_offset, readChannel, unReadRequestData?.unread_count]);

    useEffect(() => {
        const addRequestToUnread = (request_id, validate = false) => {
            const shouldUpdate = validate ? unreadRequestIds?.some(id => +id === +request_id) : true;
            if (shouldUpdate) {
                setUnreadRequestIds(prev => [...prev, request_id]);
            }
        }
        const removeRequestFromUnread = (request_id) => {
            setUnreadRequestIds(prev => prev.filter((id) => +id !== +request_id));
        }

        const unsub_status_change = unreadEmitter.subscribe(
            unreadEventsEnum.request_status_change,
            ({ request_id, status, shouldAddRequest }) => {
                // const filterCallback = (prev) => {
                //     return prev.filter(
                //         (request) => +request.resource_request_id !== +request_id
                //     );
                // };
                // const handleStatusUpdate = (pageState, pageStateRef, setPageState, requests, setRequests, request_id, shouldAddRequest) => {
                const isRequestLoaded = requests.find(req => +req.resource_request_id === +request_id);
                // const matchesFilter = isRequestLoaded && checkRequestMatchesFilter({ request: isRequestLoaded });
                // const updatedMeta = shouldAddRequest ? updateMetaOnAdd(requestsMetaRef.current) : updateMetaOnRemove(requestsMetaRef.current);
                // setRequestsMeta(updatedMeta);
                // requestsMetaRef.current = updatedMeta;

                // const getStatus = (status) => {
                //     let is_approved = 0, is_rejected = 0;
                //     if (status === "approved") {
                //         is_approved = 1;
                //     } else if (status === "rejected") {
                //         is_rejected = 1;
                //     }
                //     return { is_approved, is_rejected };
                // }

                addRequestToUnread(request_id, true);
                // if (shouldAddRequest) {
                if (isRequestLoaded) {
                    // TODO: remove request and update meta if it doesn't match applied filter
                    // setRequests(req => {
                    //     return req.map(request => {
                    //         if (+request.resource_request_id === +request_id) {
                    //             return { ...request, ...getStatus(status) };
                    //         }
                    //         return request;
                    //     });
                    // })
                    // fetchRequests({ page_number: 1, page_size: 1, flush: false, updateMeta: false, prepend: true, call_unread_API: true });
                    fetchRequests({ ...filterState, page_number: 1, page_size: 1, flush: false, updateMeta: false, updateExistingRequest: true, request_id });
                } else {
                    // TODO: load request only if filter matches
                    // removeRequestFromUnread(request_id, true);
                    // setRequests(filterCallback);
                    // if (requests.length !== requestsMeta.total_results) {
                    //     fetchRequests({ page_number: requests.length, page_size: 1, flush: false, updateMeta: false });
                    // }
                    fetchRequests({ ...filterState, page_number: 1, page_size: 1, flush: false, updateMeta: false, prepend: true, call_unread_API: true });
                }
                // };

                // handleStatusUpdate(requestsMeta, requestsMetaRef, setRequestsMeta, requests, setRequests, request_id, shouldAddRequest);
            }
        );

        const unsub_add_req = unreadEmitter.subscribe(
            unreadEventsEnum.request_added,
            ({ request_id, status }) => {
                const updatedMeta = updateMetaOnAdd(requestsMetaRef.current);
                setRequestsMeta(updatedMeta);
                requestsMetaRef.current = updatedMeta;
                addRequestToUnread(request_id);
                fetchRequests({ ...filterState, request_id, page_number: 1, page_size: 1, flush: false, updateMeta: false, prepend: true, call_unread_API: true });
            }
        );

        const unsub_edit_req = unreadEmitter.subscribe(
            unreadEventsEnum.request_edited,
            ({ request_id, status }) => {
                addRequestToUnread(request_id, true);
                const isRequestLoaded = requests.some(req => +req.resource_request_id === +request_id)
                // if request is loaded fetch and update it, else fetch and prepend
                if (isRequestLoaded) {
                    fetchRequests({ ...filterState, page_number: 1, page_size: 1, flush: false, updateMeta: false, updateExistingRequest: true, request_id });
                } else {
                    fetchRequests({ ...filterState, page_number: 1, page_size: 1, flush: false, updateMeta: false, prepend: true, call_unread_API: true });
                }
            }
        );

        const unsub_remove_req = unreadEmitter.subscribe(
            unreadEventsEnum.request_deleted,
            ({ request_id, status }) => {
                const filterCallback = (prev) => {
                    return prev.filter(
                        (request) => +request.resource_request_id !== +request_id
                    );
                };

                const updatedMeta = updateMetaOnRemove(requestsMetaRef.current);
                setRequestsMeta(updatedMeta);
                requestsMetaRef.current = updatedMeta;
                removeRequestFromUnread(request_id);
                const isRequestLoaded = requests.some(req => +req.resource_request_id === +request_id)
                setRequests(filterCallback);
                if (isRequestLoaded) {
                    if (requests.length !== requestsMeta.total_results) {
                        // REMOVED 'call_unread_API: true' as it is not needed
                        fetchRequests({ ...filterState, status, page_number: requests.length, page_size: 1, flush: false, updateMeta: false });
                    }
                }
            }
        );

        return () => {
            unsub_status_change();
            unsub_add_req();
            unsub_edit_req();
            unsub_remove_req();
        };
    }, [unreadRequestIds, fetchRequests, requests, requestsMeta, checkRequestMatchesFilter, filterState]);

    useEffect(() => {
        const controller = new AbortController();
        async function getCurrentShiftData() {
            try {
                let url = new URL(AL_BASE_URL + `/shift/${loggedInUser.resource_id}`);
                url.searchParams.append("date", format(convertTimeZone(), "YYYY-MM-DD"));
                url.searchParams.append("shift_type", "standard");
                url = url.toString();
                setIsShiftDataLoading(true);

                const response = await APIService.apiRequest(
                    url,
                    null,
                    false,
                    "GET",
                    controller
                );

                if (response.status === 1) {
                    setCurrentSchedule(() => {
                        const tzOffset = myTimeZone.utc_offset;
                        const dstTZOffset = myTimeZone.dst_utc_offset ?? tzOffset;
                        const schedule = response.output.schedule.map((s) => {
                            const start_time = getFormatTimeStr(convertHhmmToUserTimezone(s.start_time.time, tzOffset)[0])
                            const end_time = getFormatTimeStr(convertHhmmToUserTimezone(s.end_time.time, tzOffset)[0])
                            return { start_time, end_time, weekday: s.weekday, is_wfh: s.is_wfh, is_activity_tracked: s.is_activity_tracked }
                        })
                        const dst_schedule = response.output.dst_schedule.map((s) => {
                            const start_time = getFormatTimeStr(convertHhmmToUserTimezone(s.start_time.time, dstTZOffset)[0])
                            const end_time = getFormatTimeStr(convertHhmmToUserTimezone(s.end_time.time, dstTZOffset)[0])
                            return { start_time, end_time, weekday: s.weekday, is_wfh: s.is_wfh, is_activity_tracked: s.is_activity_tracked }
                        })
                        return { schedule, dst_schedule };
                    }
                    );
                }
                setIsShiftDataLoading(false);
            } catch (error) {
                setIsShiftDataLoading(false);
                alertService.error(error.message);
            }
        }

        getCurrentShiftData();
        return () => controller.abort();
    }, [loggedInUser.resource_id, myTimeZone.dst_utc_offset, myTimeZone.utc_offset]);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const request_id = searchParams.get("request_id");

        if (request_id) {
            history.replace(window.location.pathname);
        }

        fetchRequests({
            flush: true,
            ...(request_id ? {} : filterState),
            request_id: request_id,
        });

        return () => {
            abortControllerRef.current.abort();
            clearTimeout(fakeLoaderRef.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='attendance-modal requests-modal' ref={ref}>
            <div className={`requests-header ${selectedFormType ? 'disabled' : ''}`}>
                <div className='requests-menu-wrapper'>
                    {isSelectedOrgEmployee && <Panel
                        isOpen={showRequestTypeMenu}
                        onClose={toggleRequestTypeMenu}
                        transitionType='fade-in'
                        id='request-type'
                        top={isDesktopVersion ? '26px' : '28px'}
                        button={<button
                            id="request-type" className="request-create-btn"
                            onClick={() => {
                                setSelectedFormType(REQUEST_TYPES.LEAVE);
                                setSelectedLeaveType(leaveOptions[1]);
                                setSelectedShiftType(SHIFT_TYPE_OPTIONS[0]);
                            }}
                        >
                            <span className="icon icon-plus"></span>
                            <span>Request</span>
                        </button>}
                    >
                    </Panel>}
                </div>

                <Filter
                    leaveTypeOptions={leaveOptions}
                    state={filterState}
                    onClear={clearFilter}
                    onChange={onFilterChange}
                    fakeLoader={fakeLoader}
                />

                <LineLoader
                    show={showLineLoader}
                    position="absolute"
                    style={{ top: 'auto', bottom: '-1px' }}
                />
            </div>
            <div className='requests-body'>
                {selectedFormType ? requestForm(selectedFormType) : (
                    <SuperPager
                        type="infiniteScroll"
                        dataLength={showLineLoader ? 0 : requests.length}
                        loadMore={() =>
                            fetchRequests({
                                // status: "approved",
                                flush: false,
                                page_number: requestsMeta.page_number + 1,
                                page_size: requestsMeta.page_size,
                                ...filterState,
                            })
                        }
                        hasMore={
                            requestsMeta.page_number <
                            requestsMeta.num_pages
                        }
                        wrapper={true}
                        children={getRequestsContent()}
                    />
                )}
                {showDiscardChangesAlert && (
                    <AlertPopup
                        icon="warning"
                        yesHandler={() => toggle()}
                        cancelHandler={() => setShowDiscardChangesAlert(false)}
                    />
                )}
            </div>
            {revokeRequestInfo && (
                <RevokeRequestPopup
                    onClose={() => setRevokeRequestInfo(null)}
                    onRevoke={() => revokeRequest(revokeRequestInfo)}
                />
            )}
        </div>
    )
})

export default RequestsModal

const RevokeRequestPopup = ({ onClose, onRevoke }) => {
    return (
        <div className='revoke-popup-wrapper'>
            <ClickOutsideListner onOutsideClick={onClose}>
                <div className='popup'>
                    <div className='popup-content'>
                        <p>Are you sure to revoke the request?</p>
                    </div>
                    <div className='popup-actions'>
                        <button className='btn' onClick={onRevoke}>Revoke</button>
                        <button className='btn' onClick={onClose}>Cancel</button>
                    </div>
                </div>
            </ClickOutsideListner>
        </div>
    )
}